import { useEffect, useState } from "react";
import session from "../../../../services/dashboard/session";
import Cookies from "js-cookie";
import { NotesListRes } from "../../../../services/types/dashboard/session";
import { ListItemText, MenuItem, MenuList, Paper } from "@mui/material";

export const HeaderMenuNotice = (): JSX.Element => {
  const lang = Cookies.get("lang") ?? "oz";
  const [noticeData, setNoticeData] = useState<NotesListRes[]>([]);

  useEffect(() => {
    void session.note({ lang }).then((response) => {
      setNoticeData(response.data.data);
    });
  }, []);
  return (
    <Paper sx={{ width: 500, maxWidth: "100%" }}>
      <MenuList>
        {noticeData.map((item) => (
          <MenuItem sx={{ maxWidth: "initial" }} key={item.id}>
            <ListItemText>{item.title}</ListItemText>
          </MenuItem>
        ))}
      </MenuList>
    </Paper>
  );
};
