import { IRespBody } from "../../types/resp-body/resp-body";
import { httpGet, httpPost } from "../../index";
import {
  IAppointmentData,
  IAppointment,
  IStatus,
  AppoinmentParams,
  IAppointmentSource,
} from "../../../interfaces/dashboard/appointment";

const appointments = {
  index: async (params: { size: number; lang: string; page: number }) =>
    await httpGet<IRespBody<IAppointmentData>>({
      url: "/dashboard/appointment/index",
      params,
    }),
  status: async () =>
    await httpGet<IRespBody<IStatus[]>>({
      url: "/dashboard/appointment/status",
    }),
  show: async (id: number) =>
    await httpGet<IRespBody<IAppointment>>({
      url: `/dashboard/appointment/show/${id}`,
    }),
  downloadFile: async (id: number) =>
    await httpGet<Blob>({
      url: `/dashboard/appointment/download-file/${id}`,
      responseType: "blob",
    }),
  types: async (params: { lang: string }) =>
    await httpGet<IRespBody<IStatus[]>>({
      url: "/dashboard/appointment/type",
      params,
    }),
  sources: async (params: { lang: string }) =>
    await httpGet<IRespBody<IAppointmentSource>>({
      url: "/dashboard/appointment/source",
      params,
    }),
  store: async (data: AppoinmentParams) =>
    await httpPost<IRespBody<IStatus[]>>({
      url: "/dashboard/appointment/store",
      data,
    }),
  update: async (data: AppoinmentParams) =>
    await httpPost<IRespBody<IStatus[]>>({
      url: "/dashboard/appointment/update",
      data,
    }),
  receive: async (data: { id: number }) =>
    await httpPost<IRespBody<IStatus[]>>({
      url: "/dashboard/appointment/receive",
      data,
    }),
};

export default appointments;
