import { NotificationBox } from "../components/notification-box";
import { Outlet, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { useScrollToTop } from "../helpers";
import Cookies from "js-cookie";

export const HomeOutlet = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.split("/").includes("staff")) {
      Cookies.set("size", "10");
      Cookies.set("page", "0");
    }
  }, [location.pathname]);

  useScrollToTop();
  return (
    <>
      <NotificationBox />
      <Outlet />
    </>
  );
};
