import { httpDelete, httpGet, httpPost } from "../../index";
import {
  DeputyPhoneParam,
  IRemoveCauses,
  UserCreateData,
  UsersParam,
  UsersResp,
} from "../../types/dashboard/user/users";
import { IRespBody } from "../../types/resp-body/resp-body";
import { IShowUser } from "../../../interfaces/dashboard/user/show-user";
import { IBlacklistAllResp } from "./blacklist";

const user = {
  index: async (params: UsersParam) =>
    await httpGet<IRespBody<UsersResp>>({
      url: "/dashboard/users/index",
      params,
    }),
  complaints: async (
    params: { lang: string; page: number; size: number },
    id: number
  ) =>
    await httpGet<IRespBody<IBlacklistAllResp>>({
      url: `/dashboard/users/complaints/${id}`,
      params,
    }),
  show: async (id: number) =>
    await httpGet<IRespBody<IShowUser>>({ url: `/dashboard/users/show/${id}` }),
  delete: async (id: number, reasonId: number) =>
    await httpDelete({
      url: `/dashboard/users/delete/${id}/${reasonId}`,
    }),
  store: async (data: UserCreateData) =>
    await httpPost<IRespBody<{ user_id: number }>>({
      url: "/dashboard/users/store",
      data,
    }),
  addDeputyPhone: async (data: DeputyPhoneParam) =>
    await httpPost({ url: "/dashboard/users/add-deputy-phone", data }),
  doc_face_users: async (params: UsersParam) =>
    await httpGet<IRespBody<UsersResp>>({
      url: "/dashboard/users/doc-face-user",
      params,
    }),
  jurists: async (params: UsersParam) =>
    await httpGet<IRespBody<UsersResp>>({
      url: "/dashboard/users/jurists",
      params,
    }),
  removeCauses: async (params: { lang: string }) =>
    await httpGet<IRespBody<IRemoveCauses[]>>({
      url: "/dashboard/users/remove-causes",
      params,
    }),
  deleteInfoFile: async (id: number) =>
    await httpDelete({
      url: `/dashboard/users/delete-info-file/${id}`,
    }),
};

export default user;
