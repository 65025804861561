import { FooterLogoBlock } from "../atoms";
import logo from "../../../assets/media/svg-files/logo-white.svg";
import { useTranslation } from "react-i18next";

export const FooterLogo = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <FooterLogoBlock>
      <img src={logo} alt="logo" />
      <div
        className="footer-description"
        dangerouslySetInnerHTML={{ __html: t("header_title") }}
      />
    </FooterLogoBlock>
  );
};
