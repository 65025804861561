import React, { lazy, Suspense } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./locale";
import { Box, ThemeProvider } from "@mui/material";
import { theme } from "./theme-provider";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import "moment/locale/uz";
import "moment/locale/ru";
import "moment/locale/uz-latn";
import "./app/qr";
import { CLIENT_MAIN } from "./routes/client-main";
import { useStore } from "effector-react";
import { $accountData } from "./model/account";
import { DashboardLayout } from "./app/layout";
import { DASHBOARD_MAIN } from "./routes/dashboard-main";
import { HomeOutlet } from "./routes/home-outlet";
import NoMatch from "./pages/404";
import DeptorPage from "./pages/debtor";

// Client
const SignInPage = lazy(async () => await import("./pages/auth"));
const CouncilPage = lazy(async () => await import("./pages/council-page"));

const COUNCIL: RouteObject = {
  path: "council/:id",
  element: (
    <Suspense>
      <CouncilPage />
    </Suspense>
  ),
};

const SIGN: RouteObject = {
  path: "sign-in",
  element: (
    <Suspense>
      <SignInPage />
    </Suspense>
  ),
};

const NO_MATCH_FULL: RouteObject = {
  path: "*",
  element: (
    <Suspense>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "0.6em",
          boxShadow: "0 0.3em 0.5em #bababaff",
          position: "relative",
          background: "#fff",
          width: "100%",
          overflow: "hidden",
          height: "100vh",
        }}
      >
        <NoMatch />
      </Box>
    </Suspense>
  ),
};

function App(): JSX.Element {
  const userRoleId = useStore($accountData)?.data?.role_id;

  const router = createBrowserRouter([
    {
      path: "/",
      element: <HomeOutlet />,
      children: [
        COUNCIL,
        SIGN,
        CLIENT_MAIN,
        {
          path: "debtor",
          element: (
            <Suspense>
              <DeptorPage />
            </Suspense>
          ),
        },
        {
          path: "dashboard",
          element: <DashboardLayout />,
          children: [DASHBOARD_MAIN(userRoleId)],
        },
      ],
    },
    NO_MATCH_FULL,
  ]);

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        {/* <NotificationBox /> */}
        <RouterProvider router={router} />
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
