import { io } from "socket.io-client";
import Cookies from "js-cookie";

// "undefined" means the URL will be computed from the `window.location` object
const URL = process.env.REACT_APP_SOCKET_URL ?? "";
const token = Cookies.get("token") ?? undefined;

export const socket = io(URL, {
  transports: ["websocket"],
  auth: {
    token,
  },
});
