import * as React from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

// import ListItemText from "@mui/material/ListItemText";
// import Collapse from "@mui/material/Collapse";
//
// import ExpandLess from "@mui/icons-material/ExpandLess";
// import ExpandMore from "@mui/icons-material/ExpandMore";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SideMenuHome(props: any): JSX.Element {
  const { t } = useTranslation();
  // const [open, setOpen] = React.useState(false);
  //
  // const handleClick = (): void => {
  //   setOpen(!open);
  // };

  return (
    <List
      sx={{
        position: "absolute",
        width: "100%",
        maxWidth: 360,
        bgcolor: "#023188",
        color: "#fff",
        borderRadius: "8px",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton>
        {/* <ListItemText primary="Sent mail" /> */}
        <Link style={{ color: "#fff" }} to="/#council">
          {t("council_info")}
        </Link>
      </ListItemButton>
      <ListItemButton>
        <Link style={{ color: "#fff" }} to="/deputy-know">
          {t("deputy_know")}
        </Link>
      </ListItemButton>
      <ListItemButton>
        <Link style={{ color: "#fff" }} to="/senat-committee">
          {t("senat_committee")}
        </Link>
      </ListItemButton>
      <ListItemButton>
        <Link style={{ color: "#fff" }} to="/news">
          {t("news")}
        </Link>
      </ListItemButton>
      <ListItemButton>
        <Link style={{ color: "#fff" }} to="/doc">
          {t("decisions")}
        </Link>
      </ListItemButton>
      <ListItemButton>
        <Link style={{ color: "#fff" }} to="/legislation">
          {t("legislation")}
        </Link>
      </ListItemButton>
      <ListItemButton>
        <Link style={{ color: "#fff" }} to="/registry">
          {t("registry")}
        </Link>
      </ListItemButton>
    </List>
  );
}
