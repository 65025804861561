import { FooterBlock, FooterBlockIndex, UzinfocomLink } from "./atoms";
import { FooterLogo } from "./molecules";
import { UzinfocomSvg } from "../../assets/media/svg-icons";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { Container } from "../container";
import PhoneSvg from "../../assets/media/svg-files/phone.svg";
import { Telegram } from "@mui/icons-material";
import { Link } from "react-router-dom";
import React from "react";

export const Footer = (): JSX.Element => {
  const { t } = useTranslation();
  // const [show, setShow] = useState(false)

  // function scrollFunction() {
  //     if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
  //        setShow(true)
  //     } else {
  //         setShow(false)
  //     }
  // }
  //
  // useEffect(() => {
  //     window.onscroll = function() {scrollFunction()};
  //
  //     return window.removeEventListener('scroll', scrollFunction);
  // }, []);

  return (
    <FooterBlock>
      {/* <button */}
      {/*    onClick={() => { */}
      {/*        window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); */}
      {/*    }} */}
      {/*    className={`scroll-top ${show ? 'show' : ''}`} */}
      {/* > */}
      {/*    <ArrowUpOutlined/> */}
      {/* </button> */}
      <Container>
        <FooterBlockIndex>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              ".telegram": {
                marginTop: "30px",
                color: "#fff",
                display: "inline-flex",
                alignItems: "center",
                height: "50px",
                borderRadius: "13px",
                border: "1px solid #fff",
                padding: "0 16px",
                textDecoration: "none",
                svg: {
                  marginRight: "12px",
                },
              },
            }}
          >
            <FooterLogo />
            <a
              className="telegram"
              href="https://t.me/senat_markazi"
              rel="noreferrer"
              target="_blank"
            >
              <Telegram />
              <span>{t("telegram_channel")}</span>
            </a>
          </Box>
          <div className="links">
            <Link to="/about-council">{t("about_kengash")}</Link>
            <Link to="/deputy-know">{t("deputy_know")}</Link>
            <Link to="/senat-committee">{t("senat_committee")}</Link>
            <Link to="/news">{t("news")}</Link>
            <Link to="/doc">{t("decisions")}</Link>
          </div>
          <div className="useful-links">
            {/* <div> */}
            {/*  <span */}
            {/*    dangerouslySetInnerHTML={{ */}
            {/*      __html: t("footer_second_operator_text", { */}
            {/*        phone: `<b><img src="${PhoneSvg}" alt="phone">71-238-26-63</b>`, */}
            {/*      }), */}
            {/*    }} */}
            {/*  /> */}
            {/* </div> */}
            <span className="footer-border" />
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: t("footer_operator_text", {
                    phone: `<b><img src="${PhoneSvg}" alt="phone">71 202 22 02</b>`,
                  }),
                }}
              />
            </div>
          </div>
        </FooterBlockIndex>
        <Box
          sx={{
            padding: "30px 0",
            borderTop: "1px solid #fff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              fontSize: "14",
              color: "#fff",
            }}
          >
            {t("footer_message")}
          </Box>
          <UzinfocomLink>
            <a href="https://uzinfocom.uz" rel="noreferrer" target="_blank">
              <UzinfocomSvg />
            </a>
          </UzinfocomLink>
        </Box>
      </Container>
    </FooterBlock>
  );
};
