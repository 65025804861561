import React, { lazy, Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";

const NewDocPage = lazy(
  async () => await import("../pages/dashboard/docs-page/new-doc")
);
const CreateOutBoxLetterDoc = lazy(
  async () => await import("../pages/dashboard/create-new-doc/out-box/letter")
);
const OutBoxDocs = lazy(
  async () => await import("../pages/dashboard/out-box/list")
);
const ShowDoc = lazy(
  async () => await import("../pages/dashboard/out-box/show-doc")
);
const EditDoc = lazy(
  async () => await import("../pages/dashboard/out-box/edit-doc")
);
const EditOutBoxDoc = lazy(
  async () => await import("../pages/dashboard/out-box/edit")
);

const CreateProtocolPage = lazy(
  async () =>
    await import("../pages/dashboard/create-new-doc/internal/protocols")
);
const CreateDecreesPage = lazy(
  async () => await import("../pages/dashboard/create-new-doc/internal/decrees")
);
const InternalDocs = lazy(
  async () => await import("../pages/dashboard/internal-box/list")
);
const InternalEdit = lazy(
  async () => await import("../pages/dashboard/internal-box/edit")
);

const InternalShowDoc = lazy(
  async () => await import("../pages/dashboard/internal-box/show-doc")
);
const InternalEditDoc = lazy(
  async () => await import("../pages/dashboard/internal-box/edit-doc")
);
const JuristDocs = lazy(
  async () => await import("../pages/dashboard/jurist/list")
);
const JuristShowDoc = lazy(
  async () => await import("../pages/dashboard/jurist/show-doc")
);
const InstructionsPage = lazy(
  async () => await import("../pages/dashboard/instructions")
);
const HyperlinksPage = lazy(
  async () => await import("../pages/dashboard/useful/hyperlinks")
);
const NeighborhoodPage = lazy(
  async () => await import("../pages/dashboard/useful/neighborhood")
);
const JuridicalAdviceListCategoryPage = lazy(
  async () =>
    await import("../pages/dashboard/useful/juridical-advice/categories-list")
);
const JuridicalAdviceLShowPage = lazy(
  async () => await import("../pages/dashboard/useful/juridical-advice/show")
);
const Agencies = lazy(
  async () =>
    await import(
      "../pages/dashboard/useful/ministries-and-agencies/templates/agencies"
    )
);
const JuridicalAdviceMainPage = lazy(
  async () => await import("../pages/dashboard/useful/juridical-advice")
);
const MinistriesAndAgencies = lazy(
  async () => await import("../pages/dashboard/useful/ministries-and-agencies")
);
const NotificationsListPage = lazy(
  async () => await import("../pages/dashboard/notifications/list")
);
const FilePage = lazy(async () => await import("../pages/dashboard/file"));

export const JURIST_ROUTE: RouteObject[] = [
  {
    path: "file",
    children: [
      {
        path: ":code",
        element: (
          <Suspense>
            <FilePage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "new-doc",
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <NewDocPage />
          </Suspense>
        ),
      },
      {
        path: "transmittal-later",
        element: (
          <Suspense>
            <CreateOutBoxLetterDoc />
          </Suspense>
        ),
      },
      {
        path: "decrees",
        element: (
          <Suspense>
            <CreateDecreesPage />
          </Suspense>
        ),
      },
      {
        path: "protocols",
        element: (
          <Suspense>
            <CreateProtocolPage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "internal-doc",
    children: [
      {
        index: true,
        element: <Navigate to="draft" />,
      },
      {
        path: "draft",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InternalShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InternalEdit />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <InternalEditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "for-signature",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InternalShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InternalEdit />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <InternalEditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "agreement",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <InternalShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "confirmed",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <InternalShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "rejected",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <InternalShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "all-docs",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
        ],
      },
      // {
      //   path: "basket",
      //   children: [
      //     {
      //       index: true,
      //       element: (
      //         <Suspense>
      //           <InternalDocs />
      //         </Suspense>
      //       ),
      //     },
      //     {
      //       path: ":id",
      //       element: (
      //         <Suspense>
      //           <InternalShowDoc />
      //         </Suspense>
      //       ),
      //     },
      //   ],
      // },
    ],
  },
  {
    path: "out-doc",
    children: [
      { index: true, element: <Navigate to="draft" /> },
      {
        path: "draft",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditOutBoxDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "for-signature",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditOutBoxDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "agreement",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <ShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "confirmed",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <ShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "all-docs",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "jurist-doc",
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <JuristDocs />
          </Suspense>
        ),
      },
      {
        path: "draft",
        children: [
          {
            path: ":id",
            element: (
              <Suspense>
                <JuristShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "agreement",
        children: [
          {
            path: ":id",
            element: (
              <Suspense>
                <JuristShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "confirmed",
        children: [
          {
            path: ":id",
            element: (
              <Suspense>
                <JuristShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "for-signature",
        children: [
          {
            path: ":id",
            element: (
              <Suspense>
                <JuristShowDoc />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "instructions",
    element: (
      <Suspense>
        <InstructionsPage />
      </Suspense>
    ),
  },
  {
    path: "useful-info",
    children: [
      {
        path: "hyperlinks",
        element: (
          <Suspense>
            <HyperlinksPage />
          </Suspense>
        ),
      },
      {
        path: "neighborhood",
        element: (
          <Suspense>
            <NeighborhoodPage />
          </Suspense>
        ),
      },
      {
        path: "juridical-advice",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <JuridicalAdviceMainPage />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <JuridicalAdviceListCategoryPage />
                  </Suspense>
                ),
              },
              {
                path: ":showId",
                element: (
                  <Suspense>
                    <JuridicalAdviceLShowPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "ministries-and-agencies",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <MinistriesAndAgencies />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <Agencies />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "notifications",
    element: (
      <Suspense>
        <NotificationsListPage />
      </Suspense>
    ),
  },
];
