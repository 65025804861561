import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";
import Cookies from "js-cookie";
import i18next from "i18next";
import { resetUserInfo } from "../model/account";
import { tosterOpen } from "../model/app";
import { IToken } from "../interfaces/dashboard/auth/token";
import { IRespBody } from "./types/resp-body/resp-body";

let isRefreshing = false;
let refreshSubscribers: Function[] = [];
const apiVersion = "v2";
const apiService = `${process.env.REACT_APP_API_SERVICE ?? ""}/${apiVersion}`;

const httpClient = axios.create({
  baseURL: apiService,
});

function subscribeTokenRefresh(cb: (token: string) => void): void {
  refreshSubscribers.push(cb);
}

function onRefreshed(token: string): void {
  refreshSubscribers.map((cb: any) => cb(token));
  refreshSubscribers = [];
}

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      config,
      response: { status, data },
    } = error;
    const originalRequest = config;
    const refreshToken: string | undefined | null = Cookies.get("token");
    if (status === 401) {
      if (refreshToken != null) {
        if (!isRefreshing) {
          isRefreshing = true;
          axios({
            method: "post",
            url: apiService + "/dashboard/auth/refresh",
            headers: { Authorization: `Bearer ${refreshToken}` },
          })
            .then((res: AxiosResponse<IRespBody<IToken>>) => {
              isRefreshing = false;
              onRefreshed(res.data.data.access_token);
              Cookies.set("token", res.data.data.access_token);
              return originalRequest;
            })
            .catch(async () => {
              isRefreshing = false;
              resetUserInfo();
              Cookies.remove("token");
              return await Promise.reject(error);
            });
        }
        return new Promise((resolve) => {
          subscribeTokenRefresh((token: string) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axios(originalRequest));
          });
        });
      }
      Cookies.remove("token");
      isRefreshing = false;
    } else if (
      status === 403 &&
      !isNaN(data?.error?.internal_error_code as number) &&
      (data?.error?.internal_error_code as number) === 4003
    ) {
      Cookies.remove("token");
      Cookies.remove("department_id");
      Cookies.remove("role_id");
      Cookies.remove("authority_id");
      resetUserInfo();
      window.location.href = "/debtor";
    } else if (status >= 400 && status < 500) {
      if (typeof data?.error?.message === "string") {
        tosterOpen({ message: data.error.message, severity: "warning" });
        return Promise.reject(error);
      } else {
        console.error(data);
        tosterOpen({
          message: `${i18next.t("error")} ${status as string}`,
          severity: "warning",
        });
        return Promise.reject(error);
      }
    } else {
      if (typeof data?.error?.message === "string") {
        tosterOpen({ message: data.error.message, severity: "error" });
        return Promise.reject(error);
      } else {
        console.error(data);
        tosterOpen({
          message: `${i18next.t("error")} ${status as string}`,
          severity: "error",
        });
        return Promise.reject(error);
      }
    }
  }
);

httpClient.interceptors.request.use((config) => {
  const token = Cookies.get("token");
  const lang = Cookies.get("lang") ?? "oz";
  const roleId = Cookies.get("role_id");
  const authorityId = Cookies.get("authority_id");
  const departmentId = Cookies.get("department_id");

  if (
    config.method?.toLocaleLowerCase() === "post" &&
    Boolean(config.data) &&
    config.url !== "/dashboard/file/store" &&
    config.url !== "/dashboard/file/store-application" &&
    config.url !== "/dashboard/news/store" &&
    config.url !== "/dashboard/news/update" &&
    config.url !== "/dashboard/file/multi-store" &&
    config.url !== "/dashboard/executors/store" &&
    config.url !== "/dashboard/executors/update" &&
    config.url !== "/dashboard/documents/outgoing/store-receiver-authorities" &&
    config.url !== "/dashboard/documents/agreement/store" &&
    config.url !== "/dashboard/documents/report/agenda-store" &&
    config.url !== "/dashboard/documents/agreement/send-to-council" &&
    config.url !== "/dashboard/file/cancel-store"
  ) {
    const form = new FormData();

    Object.keys(config.data).forEach((key) => {
      if (Array.isArray(config.data[key])) {
        config.data[key].forEach((value: any) => {
          if (value !== undefined) {
            form.append(`${key}[]`, value);
          }
        });
      } else {
        if (config.data[key] !== undefined) {
          form.append(key, config.data[key]);
        }
      }
    });
    config.data = form;
  }

  if (token != null && config.headers != null) {
    config.headers = Object.assign(config.headers, {
      Authorization: `Bearer ${token}`,
    });
  }
  if (lang != null && config.headers != null)
    config.headers = Object.assign(config.headers, {
      "Accept-Language": lang,
    });

  if (roleId != null && config.headers != null)
    config.headers = Object.assign(config.headers, {
      "X-Role-ID": roleId,
    });
  if (authorityId != null && config.headers != null)
    config.headers = Object.assign(config.headers, {
      "X-Authority-Id": authorityId,
    });
  if (departmentId != null && config.headers != null)
    config.headers = Object.assign(config.headers, {
      "X-Department-Id": departmentId,
    });

  return config;
});

interface IParams<D = {}, P = {}> {
  url: string;
  headers?: any;
  data?: D;
  params?: P;
  onUploadProgress?: AxiosRequestConfig["onUploadProgress"];
  responseType?: ResponseType;
}

export const httpGet = async <T = never, R = AxiosResponse<T>>(
  params: IParams
): Promise<R> =>
  await httpClient({
    method: "get",
    ...params,
  });

export const httpPost = async <R = never>(
  params: IParams
): Promise<AxiosResponse<R>> => {
  return await httpClient({
    method: "post",
    ...params,
  });
};

export const httpPut = async <T = never, R = AxiosResponse<T>>(
  params: IParams
): Promise<R> =>
  await httpClient({
    method: "put",
    ...params,
  });

export const httpPatch = async <T = never, R = AxiosResponse<T>>(
  params: IParams
): Promise<R> =>
  await httpClient({
    method: "patch",
    ...params,
  });

export const httpDelete = async <T = never, R = AxiosResponse<T>>(
  params: IParams
): Promise<R> =>
  await httpClient({
    method: "delete",
    ...params,
  });
