import { Alert, Box, IconButton, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStore } from "effector-react";
import {
  $devMode,
  $tosterData,
  checkDevModeEvent,
  tosterClose,
} from "../../model/app";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import hideAuth from "../../services/dashboard/hide-auth";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { resetUserInfo } from "../../model/account";

export const NotificationBox = (): JSX.Element => {
  const toster = useStore($tosterData);
  const { t } = useTranslation();
  const devMode = useStore($devMode);
  const [clientId, setClientId] = useState<undefined | string>(undefined);
  const [documentId, setDocumentId] = useState<undefined | string>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const changeClientId = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setClientId(e.target.value);
  };

  const changeDocumentId = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setDocumentId(e.target.value);
  };

  const authClient = (): void => {
    if (clientId !== undefined && !isNaN(Number(clientId))) {
      setLoading(true);
      const data = {
        user_id: Number(clientId),
      };
      void hideAuth
        .hideLogin(data)
        .then((response) => {
          setLoading(false);
          Cookies.set("token", response.data.data.access_token, {
            expires: response.data.data.expires_in,
          });
          Cookies.remove("authority_id");
          Cookies.remove("department_id");
          Cookies.remove("role_id");

          window.location.reload();
        })
        .catch((_) => {
          setLoading(false);
          Cookies.remove("token");
          resetUserInfo();
          navigate("/sign-in");
        });
    }
  };

  const handleAuthClientDocumentId = (): void => {
    if (documentId !== undefined && !isNaN(Number(documentId))) {
      setLoading(true);
      const data = {
        document_id: Number(documentId),
      };
      void hideAuth
        .hideLogin(data)
        .then((response) => {
          setLoading(false);
          Cookies.set("token", response.data.data.access_token, {
            expires: response.data.data.expires_in,
          });
          Cookies.remove("authority_id");
          Cookies.remove("department_id");
          Cookies.remove("role_id");
          void hideAuth.checkDocument(data).then((res) => {
            if (res.status === 200) {
              // window.location.href = `/dashboard/internal-doc/draft/${documentId}`;
              Cookies.set(
                "authority_id",
                res.data.data.document_authority_id.toString()
              );
              if (res.data.data.type_id === 3) {
                window.location.href = `/dashboard/internal-doc/draft/${documentId}`;
              } else if (res.data.data.type_id === 2) {
                window.location.href = `/dashboard/out-doc/draft/${documentId}`;
              } else if (res.data.data.type_id === 1) {
                window.location.href = `/dashboard/in-doc/draft/${documentId}`;
              }
            }
          });
        })
        .catch((_) => {
          setLoading(false);
          Cookies.remove("token");
          resetUserInfo();
          navigate("/sign-in");
        });
    }
  };

  const handleKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Enter") {
      authClient();
    }
  };
  const handleKeyPressDoc = (
    event: React.KeyboardEvent<HTMLInputElement>
  ): void => {
    if (event.key === "Enter") {
      handleAuthClientDocumentId();
    }
  };

  useEffect(() => {
    checkDevModeEvent();
  }, []);

  return (
    <Box>
      {devMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            background: "#fff",
            zIndex: "99999",
          }}
        >
          <TextField
            value={clientId}
            onChange={changeClientId}
            placeholder="CLIENT ID"
            style={{
              width: "120px",
            }}
            disabled={loading}
            onKeyPress={handleKeyPress}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={authClient}
            disabled={loading}
          >
            <CheckCircleIcon />
          </IconButton>
        </Box>
      )}
      {devMode && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            position: "fixed",
            bottom: "20px",
            right: "200px",
            background: "#fff",
            zIndex: "99999",
          }}
        >
          <TextField
            value={documentId}
            onChange={changeDocumentId}
            placeholder="DOC ID"
            style={{
              width: "120px",
            }}
            disabled={loading}
            onKeyPress={handleKeyPressDoc}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={handleAuthClientDocumentId}
            disabled={loading}
          >
            <CheckCircleIcon />
          </IconButton>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={toster.open}
        autoHideDuration={3000}
        onClose={() => {
          tosterClose();
        }}
      >
        <Alert
          onClose={() => {
            tosterClose();
          }}
          severity={toster.severity}
          sx={{ width: "100%" }}
        >
          {t(toster.message)}
        </Alert>
      </Snackbar>
    </Box>
  );
};
