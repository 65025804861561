import { httpGet, httpPost } from "../index";
import { IRespBody } from "../types/resp-body/resp-body";
import { CheckResp } from "../../interfaces/dashboard/auth/check";
import {
  CheckDocumentParam,
  HiddenLoginParam,
} from "../types/dashboard/auth/hidden-login";
import {
  ICheckDocumentResp,
  IToken,
} from "../../interfaces/dashboard/auth/token";

export const hideAuth = {
  checkDevMode: async () =>
    await httpGet<IRespBody<CheckResp>>({ url: "/dashboard/auth/check" }),
  hideLogin: async (data: HiddenLoginParam) =>
    await httpPost<IRespBody<IToken>>({
      url: "/dashboard/auth/hidden-login",
      data,
    }),
  checkDocument: async (params: CheckDocumentParam) =>
    await httpGet<IRespBody<ICheckDocumentResp>>({
      url: "/dashboard/auth/check-document",
      params,
    }),
};

export default hideAuth;
