import React, { lazy, Suspense } from "react";
import { ROUTES } from "./index";
import { RouteObject, useRouteError } from "react-router-dom";
import { Box } from "@mui/material";
import NoMatch from "../pages/404";
import { useTranslation } from "react-i18next";
const DashboardMainPage = lazy(async () => await import("../pages/main"));

const NO_MATCH: RouteObject = {
  path: "*",
  element: (
    <Suspense>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          borderRadius: "0.6em",
          boxShadow: "0 0.3em 0.5em #bababaff",
          position: "relative",
          background: "#fff",
          width: "100%",
          overflow: "hidden",
          height: "calc(100vh - 112px)",
        }}
      >
        <NoMatch />
      </Box>
    </Suspense>
  ),
};

const ErrorElementComponent = (): JSX.Element => {
  const { t } = useTranslation();
  const errorResp: unknown = useRouteError();
  if (errorResp != null) {
    const error = errorResp as { status: number };
    console.log("props", error?.status);
    if (error?.status === 404) {
      return <NoMatch />;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }

    if (error.status === 418) {
      return <div>🫖</div>;
    }
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "28px",
        fontWeight: 700,
        width: "100%",
        height: "100%",
      }}
    >
      {t("something_went_wrong")}
    </Box>
  );
};

export const DASHBOARD_MAIN = (userRoleId?: number): RouteObject => {
  return {
    path: "/dashboard",
    errorElement: <ErrorElementComponent />,
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <DashboardMainPage />
          </Suspense>
        ),
      },
      ...ROUTES(userRoleId),
      NO_MATCH,
    ],
  };
};
