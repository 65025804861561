import { styled } from "@mui/material/styles";

export const FooterBlockIndex = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
  .useful-links {
    font-size: 18px;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    div {
      margin-bottom: 32px;
    }
    b {
      font-size: 20px;
      font-weight: 500;
      margin-top: 12px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 16px;
        margin-bottom: -3px;
      }
    }
  }
  .links {
    display: inline-flex;
    flex-direction: column;
    a {
      color: #fff;
      text-decoration: none;
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: 500;
    }
  }
`;

export const FooterBlock = styled("footer")`
  background: #2455a2;
  display: flex;
  padding-top: 60px;
`;

export const FooterLogoBlock = styled("div")`
  display: flex;
  align-items: flex-start;
  .footer-description {
    font-size: 17px;
    color: #ffffff;
    margin-left: 24px;
    text-transform: uppercase;
    max-width: 278px;
    font-weight: 600;
    height: 84px;
    display: inline-flex;
    align-items: center;
  }
`;

export const IstFundLinkUi = styled("a")`
  max-width: 750px;
  width: 100%;
  margin: 55px auto 0;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 17px;
  z-index: 1;

  &:focus,
  &:hover {
    color: #ffffff;
  }

  .ist-fund-description {
    margin-left: 15px;
    padding-left: 15px;
    border-left: 1px solid #ffffff;
    flex: 1;
  }
`;

export const UzinfocomLink = styled("div")`
  display: flex;
  height: 100%;
`;
