import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { Header } from "./organisms";
import { useStore } from "effector-react";
import { $account } from "../../../model/account";
import { IStore } from "../../../interfaces/utility";
import { IMeInfo } from "../../../interfaces/dashboard/auth/me-info";

export const DashboardLayout = (): JSX.Element => {
  const userData: IStore<IMeInfo> = useStore($account).$accountData;
  return userData.data === undefined ? (
    <Navigate to="/sign-in" />
  ) : (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <Header>
        <Outlet />
      </Header>
    </Box>
  );
};
