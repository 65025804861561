import {
  CheckDocumentSvg,
  InternalDocumentSvg,
  OutDocumentSvg,
} from "../assets/media/svg-icons";
import React from "react";
import { IEventGetData } from "../services/types/socket";
import { NavProps } from "../interfaces/utility";

export const BoardManagerDeputyCategories = (
  badgeNotice: IEventGetData | null
): NavProps[] => {
  return [
    {
      title: "home_page",
      link: "/dashboard",
      end: true,
    },
    {
      title: "incoming",
      link: "in-doc",
      count: badgeNotice?.incoming_count_all ?? undefined,
      icon: <CheckDocumentSvg />,
      children: [
        {
          title: "for_signature",
          link: "in-doc/for-signature",
          count: badgeNotice?.incoming_count_signing ?? undefined,
        },
        {
          title: "confirmation",
          link: "in-doc/confirmation",
          count: badgeNotice?.incoming_count_confirmation ?? undefined,
        },
        // {
        //   title: "all_docs",
        //   link: "in-doc/all-docs",
        //   count: badgeNotice?.incoming_count_all ?? undefined,
        // },
        {
          title: "new",
          link: "in-doc/draft",
          count: badgeNotice?.incoming_count_new ?? undefined,
        },
      ],
    },
    {
      title: "outgoing_documents",
      link: "out-doc",
      count: badgeNotice?.outgoing_count_all ?? undefined,
      icon: <OutDocumentSvg />,
      children: [
        {
          title: "for_signature",
          link: "out-doc/for-signature",
          count: badgeNotice?.outgoing_count_signing ?? undefined,
        },
        {
          title: "confirmed",
          link: "out-doc/confirmed",
          count: badgeNotice?.outgoing_count_approved ?? undefined,
        },
        {
          title: "rejected",
          link: "out-doc/rejected",
          count: badgeNotice?.outgoing_count_rejected ?? undefined,
        },
        // {
        //   title: "all_docs",
        //   link: "out-doc/all-docs",
        //   count: badgeNotice?.outgoing_count_all ?? undefined,
        // },
      ],
    },
    {
      title: "internal_doc",
      link: "internal-doc",
      count: badgeNotice?.internal_count_all ?? undefined,
      icon: <InternalDocumentSvg />,
      children: [
        {
          title: "for_signature",
          link: "internal-doc/for-signature",
          count: badgeNotice?.internal_count_signing ?? undefined,
        },
        {
          title: "confirmed",
          link: "internal-doc/confirmed",
          count: badgeNotice?.internal_count_approved ?? undefined,
        },
        {
          title: "rejected",
          link: "internal-doc/rejected",
          count: badgeNotice?.internal_count_reject ?? undefined,
        },
        // {
        //   title: "basket",
        //   link: "internal-doc/basket",
        //   count: badgeNotice?.basket ?? undefined,
        // },
        // {
        //   title: "all_docs",
        //   link: "internal-doc/all-docs",
        //   count: badgeNotice?.internal_count_all ?? undefined,
        // },
      ],
    },
    {
      title: "instructions",
      link: "instructions",
      icon: <CheckDocumentSvg />,
    },
  ];
};
