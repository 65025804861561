import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LogoPng from "../../../src/assets/media/svg-files/logo.svg";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const DeptorPage = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#091f7bcc",
          paddingLeft: "100px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            margin: "10px",
            img: {
              width: "50px",
            },
            a: {
              display: "inline-flex",
              alignItems: "center",
              textDecoration: "none",
            },
            span: {
              width: "300px",
              color: "#ffffff",
              marginLeft: "30px",
              textTransform: "uppercase",
            },
          }}
        >
          <Link to="/">
            <img src={LogoPng} alt="logo" />
            <span>{t("header_title")}</span>
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "30px 100px 0",
        }}
      >
        <Box
          sx={{
            height: "100%",
            borderRadius: "8px",
            border: "2px solid #D38787FF",
            fontSize: "30px",
            padding: "30px",
            color: "#e35757",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              svg: {
                width: "110px",
                height: "110px",
              },
            }}
          >
            <WarningAmberIcon />
          </Box>
          <Box
            sx={{
              fontWeight: "700",
              fontSize: "40px",
            }}
          >
            403
          </Box>
          <Box
            sx={{
              marginBottom: "80px",
            }}
          >
            {t("debtor_attention")}
          </Box>
          <Box
            sx={{
              textAlign: "start",
              margin: "0 50px",
              color: "#000",
            }}
          >
            <div>{t("phones")}:</div>
            <div>71 238 42 37</div>
            <div>71 202 22 02 qo‘shimcha 702 yoki 704</div>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DeptorPage;
