import {
  AddDocumentSvg,
  CheckDocumentSvg,
  OutDocumentSvg,
} from "../assets/media/svg-icons";
import React from "react";
import { IEventGetData } from "../services/types/socket";
import { NavProps } from "../interfaces/utility";

export const SenatorCategories = (
  badgeNotice: IEventGetData | null
): NavProps[] => {
  return [
    {
      title: "home_page",
      link: "/dashboard",
      end: true,
    },
    {
      title: "add_new_document",
      link: "new-doc",
      icon: <AddDocumentSvg />,
    },
    {
      title: "incoming",
      link: "in-doc",
      count: badgeNotice?.incoming_count_all ?? undefined,
      icon: <CheckDocumentSvg />,
      children: [
        {
          title: "for_signature",
          link: "in-doc/for-signature",
          count: badgeNotice?.incoming_count_signing ?? undefined,
        },
        {
          title: "execution",
          link: "in-doc/execution",
          count: badgeNotice?.incoming_count_execution ?? undefined,
        },
        {
          title: "confirmation",
          link: "in-doc/confirmation",
          count: badgeNotice?.incoming_count_confirmation ?? undefined,
        },
        {
          title: "executed",
          link: "in-doc/executed",
          count: badgeNotice?.incoming_count_executed ?? undefined,
        },
        {
          title: "all_docs",
          link: "in-doc/all-docs",
          count: badgeNotice?.incoming_count_all ?? undefined,
        },
      ],
    },
    {
      title: "outgoing_documents",
      link: "out-doc",
      count: badgeNotice?.outgoing_count_all ?? undefined,
      icon: <OutDocumentSvg />,
      children: [
        {
          title: "draft",
          link: "out-doc/draft",
          count: badgeNotice?.outgoing_count_draft ?? undefined,
        },
        {
          title: "for_signature",
          link: "out-doc/for-signature",
          count: badgeNotice?.outgoing_count_signing ?? undefined,
        },
        {
          title: "agreement",
          link: "out-doc/agreement",
          count: badgeNotice?.outgoing_count_agreement ?? undefined,
        },
        {
          title: "confirmed",
          link: "out-doc/confirmed",
          count: badgeNotice?.outgoing_count_approved ?? undefined,
        },
        {
          title: "all_docs",
          link: "out-doc/all-docs",
          count: badgeNotice?.outgoing_count_all ?? undefined,
        },
      ],
    },
    {
      title: "instructions",
      link: "instructions",
      icon: <CheckDocumentSvg />,
    },
  ];
};
