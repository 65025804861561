//! moment.js locale configuration
//! locale : Uzbek Latin [qr]
//! author : Rasulbek Mirzayev : github.com/Rasulbeeek

(function (global, factory) {
  typeof exports === "object" &&
  typeof module !== "undefined" &&
  typeof require === "function"
    ? factory(require("moment/moment"))
    : typeof define === "function" && define.amd
    ? define(["moment/moment"], factory)
    : factory(global.moment);
})(this, function (moment) {
  "use strict";

  //! moment.js locale configuration

  var qr = moment.defineLocale("qr", {
    months:
      "Январь_Февраль_Март_Апрель_Май_Июнь_Июль_Август_Сентябрь_Октябрь_Ноябрь_Декабрь".split(
        "_"
      ),
    monthsShort: "Ян_Фев_Мар_Апр_Май_Июн_Июл_Авг_Сен_Окт_Ной_Дек".split("_"),
    weekdays: "Екшемби_Дүйшемби_Сийшемби_Сәршемби_Пийшемби_Жума_Шемби".split(
      "_"
    ),
    weekdaysShort: "Ек_Дүй_Сий_Сәр_Пий_Жу_Ше".split("_"),
    weekdaysMin: "Ya_Du_Se_Cho_Pa_Ju_Sha".split("_"),
    longDateFormat: {
      LT: "HH:mm",
      LTS: "HH:mm:ss",
      L: "DD/MM/YYYY",
      LL: "D MMMM YYYY",
      LLL: "D MMMM YYYY HH:mm",
      LLLL: "D MMMM YYYY, dddd HH:mm",
    },
    calendar: {
      sameDay: "[Бүгин саат] LT [де]",
      nextDay: "[Ертең] LT [де]",
      nextWeek: "dddd [күни саат] LT [де]",
      lastDay: "[Кеше саат] LT [де]",
      lastWeek: "[Өткен] dddd [күни саат] LT [де]",
      sameElse: "L",
    },
    relativeTime: {
      future: "жақын %s ишинде",
      past: "Бир неше  %s алдын",
      s: "секунд",
      ss: "%d секунд",
      m: "бир минут",
      mm: "%d минут",
      h: "бир саат",
      hh: "%d саат",
      d: "Бир күн",
      dd: "%d куе",
      M: "Бир ай",
      MM: "%d ай",
      y: "Бир жыл",
      yy: "%d жыл",
    },
    week: {
      dow: 1, // Monday is the first day of the week.
      doy: 7, // The week that contains Jan 7th is the first week of the year.
    },
  });

  return qr;
});
