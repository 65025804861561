import { createEvent } from "effector";
import { fetchGetAccountFx } from "./index";

export const getUserInfo = createEvent<string>();

export const changeRoleEvent = createEvent<number>();
export const changeAuthorityEvent = createEvent<number>();
export const changeDepartmentEvent = createEvent<number>();
export const resetUserInfo = createEvent();

getUserInfo.watch(fetchGetAccountFx);
