import { combine, createStore } from "effector";
import { fetchGetAccountFx } from "./effects";
import {
  changeAuthorityEvent,
  changeDepartmentEvent,
  changeRoleEvent,
  resetUserInfo,
} from "./events";
import { IStore } from "../../interfaces/utility";
import {
  AuthoritiesList,
  DepartmentsList,
  IMeInfo,
} from "../../interfaces/dashboard/auth/me-info";
import Cookies from "js-cookie";
import { IRole } from "../../interfaces/dashboard/role/role";

export const $accountData = createStore<IStore<IMeInfo>>({
  data: undefined,
  loading: false,
})
  .on(fetchGetAccountFx.pending, (state, pending) => ({
    ...state,
    loading: pending,
  }))
  .on(fetchGetAccountFx.doneData, (state, res) => {
    const validAuthorities: AuthoritiesList | undefined =
      res.data.data.authorities.find(
        (item) => item.id.toString() === Cookies.get("authority_id")
      );
    const validDepartment: DepartmentsList | undefined =
      validAuthorities?.departments.find(
        (item) => item.id.toString() === Cookies.get("department_id")
      );
    const validRole: IRole | undefined = validAuthorities?.roles.find(
      (item) => item.id.toString() === Cookies.get("role_id")
    );
    if (validRole === undefined && validAuthorities?.roles[0]?.id != null) {
      Cookies.set("role_id", validAuthorities?.roles[0].id.toString());
    }
    if (validAuthorities === undefined) {
      Cookies.remove("authority_id");
    }
    if (validDepartment === undefined) {
      if (validAuthorities?.departments[0]?.id != null) {
        Cookies.set(
          "department_id",
          validAuthorities?.departments[0]?.id.toString()
        );
      } else {
        Cookies.remove("department_id");
      }
    }
    return {
      ...state,
      data: {
        ...res.data.data,
        role_id: validRole?.id ?? validAuthorities?.roles[0]?.id ?? 0,
        department_id:
          validDepartment?.id ??
          validAuthorities?.departments[0]?.id ??
          undefined,
        authority_id: validAuthorities?.id ?? undefined,
        roles: validAuthorities?.roles ?? [],
      },
    };
  })
  .on(changeRoleEvent, (state, roleId) => {
    Cookies.set("role_id", roleId.toString());
    return {
      ...state,
      data:
        state.data !== undefined
          ? {
              ...state.data,
              role_id: roleId,
            }
          : undefined,
    };
  })
  .on(changeAuthorityEvent, (state, authorityId) => {
    const validAuthorities: AuthoritiesList | undefined =
      state.data?.authorities.find((item) => item.id === authorityId);
    const validRole = validAuthorities?.roles[0]?.id;
    const departmentId = validAuthorities?.departments[0]?.id;
    Cookies.set("authority_id", authorityId.toString());
    departmentId != null &&
      Cookies.set("department_id", departmentId.toString());
    validRole != null && Cookies.set("role_id", validRole.toString());

    return {
      ...state,
      data:
        state.data !== undefined && validAuthorities?.roles != null
          ? {
              ...state.data,
              authority_id: authorityId,
              department_id: departmentId,
              roles: validAuthorities?.roles,
              role_id: validAuthorities?.roles[0]?.id,
            }
          : undefined,
    };
  })
  .on(changeDepartmentEvent, (state, departmentId) => {
    Cookies.set("department_id", departmentId.toString());

    return {
      ...state,
      data:
        state.data !== undefined
          ? {
              ...state.data,
              department_id: departmentId,
            }
          : undefined,
    };
  })
  .reset(resetUserInfo);

export const $account = combine({
  $accountData,
});
