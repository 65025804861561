import { NavProps } from "../interfaces/utility";
import MonitorIcon from "@mui/icons-material/Monitor";
import React from "react";
import { CheckDocumentSvg } from "../assets/media/svg-icons";

export const MonitoringSenateCategories: NavProps[] = [
  {
    title: "monitoring",
    link: "monitoring",
    icon: <MonitorIcon />,
    children: [
      {
        title: "main",
        link: "monitoring/main",
      },
      {
        title: "composition_councils",
        link: "monitoring/composition-councils",
      },
      {
        title: "quality_composition",
        link: "monitoring/quality-composition",
      },
      {
        title: "reports",
        link: "monitoring/reports",
      },
      {
        title: "signed_docs",
        link: "monitoring/doc",
      },
      {
        title: "council_decision",
        link: "monitoring/council-decision",
      },
      {
        title: "deputy_request",
        link: "monitoring/deputy-request",
      },
    ],
  },
  {
    title: "instructions",
    link: "instructions",
    icon: <CheckDocumentSvg />,
  },
];
