import { createEvent } from "effector";
import { TosterType } from "../../interfaces/utility";
import { fetchDevModeFx } from "./effects";

export const tosterOpen = createEvent<TosterType>();
export const tosterClose = createEvent();

export const checkDevModeEvent = createEvent();

checkDevModeEvent.watch(fetchDevModeFx);
