import React, { useEffect } from "react";
import { Container } from "../../components/container";
import { DeputyKnowWrapper } from "../deputy-know/atoms";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Cookies from "js-cookie";
import registries, {
  IRecurringRegistryResp,
  // RegistriesClientResp,
} from "../../services/client/registry";
import { useTranslation } from "react-i18next";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&::before": {
    display: "none",
  },
  marginBottom: "15px",
  borderRadius: "8px !important",
  padding: "0px 20px",
  fontFamily: "Monserrat, sans-serif !important",
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  // backgroundColor:
  //   theme.palette.mode === "dark"
  //     ? "rgba(255, 255, 255, .05)"
  //     : "rgba(0, 0, 0, .03)",
  flexDirection: "row",
  minHeight: "80px",
  padding: "0px !important",
  // marginBottom: "15px",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "15px 0px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ConditionalIcon = ({
  expanded,
}: {
  expanded: string | boolean;
}): JSX.Element =>
  expanded !== false ? (
    <Box
      sx={{
        fontSize: "32px",
        borderRadius: "8px",
        color: "#fff",
        width: "40px",
        height: "40px",
        background: "#2455A2",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <span style={{ marginTop: "-5px" }}>-</span>
    </Box>
  ) : (
    <Box
      sx={{
        fontSize: "32px",
        borderRadius: "8px",
        color: "#2455A2",
        width: "40px",
        height: "40px",
        background: "rgba(200, 212, 231, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pb: "0px",
      }}
    >
      +
    </Box>
  );

const RegistryPage = (): JSX.Element => {
  const { t } = useTranslation();
  const lang = Cookies.get("lang") ?? "oz";
  const [registry, setRegistry] = React.useState<IRecurringRegistryResp[] | []>(
    []
  );

  // const [expanded, setExpanded] = React.useState<string | false>(false);
  const [expandedItems, setExpandedItems] = React.useState<number[]>([]);

  const handleToggle =
    (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      const newExpandedItems = [...expandedItems]; // Copy the array
      if (newExpanded) {
        newExpandedItems.push(panel); // Add ID to expanded array if newly expanded
      } else {
        const index = newExpandedItems.indexOf(panel); // Find the index of the ID
        if (index !== -1) {
          newExpandedItems.splice(index, 1); // Remove ID from expanded array if collapsed
        }
      }
      setExpandedItems(newExpandedItems);
    };

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //   };

  useEffect(() => {
    const params = {
      lang,
      page: 0,
      size: 30,
    };
    void registries.index(params).then((res) => {
      if (res.status === 200) {
        setRegistry(res.data.data);
      } else {
        setRegistry([]);
      }
    });
  }, [lang]);

  const RecursiveAccordion = (props: {
    data?: IRecurringRegistryResp[];
    // expanded?: IOpen;
  }): JSX.Element => {
    return (
      <>
        {props.data?.map((item, index) => (
          <Accordion
            key={item.id}
            // expanded={expanded === "panel" + `${item.id + 1}`}
            expanded={expandedItems.includes(item.id)}
            // onChange={handleChange("panel" + `${item.id + 1}`)}
            onChange={handleToggle(Number(item.id))}
          >
            <AccordionSummary
              expandIcon={
                <Box>
                  <ConditionalIcon
                    expanded={expandedItems.includes(item.id)}
                    // expanded={expanded === "panel" + `${item.id + 1}`}
                  />
                </Box>
              }
              aria-controls={`panel${item.id + 1}d-content`}
              id={`panel${item.id + 1}d-header`}
            >
              <Typography
                fontWeight={700}
                sx={{
                  fontFamily: "inherit !important",
                  fontSize: "20px",
                  color: expandedItems.includes(item.id)
                    ? "#2455A2"
                    : "#050F27",
                }}
              >
                {item.title}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              {item.child.length > 0 ? (
                <RecursiveAccordion data={item.child} key={item.id} />
              ) : (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: "15px",
                    color: "#454C5D",
                    columnGap: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "10px",
                        height: "10px",
                        minWidth: "10px",
                        minHeight: "10px",
                        borderRadius: "50%",
                        background: "#407BFF",
                      }}
                    />
                    <span>{item.title}</span>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "5px",
                    }}
                  >
                    {item?.child_links?.map((linkItem, linkIndex) => (
                      <Box
                        key={linkIndex}
                        sx={{
                          cursor: "pointer",
                          background: "#EFF3F8",
                          width: "250px !important",
                          minWidth: "250px !important",
                          // height: "72px",
                          borderRadius: "8px",
                          color: "#2455A2",
                          p: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          // fontSize: "8px",
                          boxSizing: "unset",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <span>
                            {" "}
                            <ErrorOutlineIcon
                              sx={{
                                fontSize: "16px",
                                mr: "5px",
                                display: "flex",
                                alignItems: "flex-start",
                              }}
                            />
                          </span>
                          <a
                            href={linkItem.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{
                                fontFamily: "inherit !important",
                                "&:hover": { textDecoration: "underline" },
                                fontSize: "12px",
                                textAlign: "center",
                              }}
                            >
                              {linkItem.link_title}
                            </Typography>
                          </a>
                        </div>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
              {/* {item?.child?.map((child) => */}
              {/*  child.child.length > 0 ? ( */}
              {/*    <RecursiveAccordion data={child.child} key={child.id} /> */}
              {/*  ) : ( */}
              {/*    <Box */}
              {/*      key={child.id} */}
              {/*      sx={{ */}
              {/*        display: "flex", */}
              {/*        justifyContent: "space-between", */}
              {/*        alignItems: "center", */}
              {/*        mb: "15px", */}
              {/*        color: "#454C5D", */}
              {/*        columnGap: "10px", */}
              {/*      }} */}
              {/*    > */}
              {/*      <Box */}
              {/*        sx={{ */}
              {/*          display: "flex", */}
              {/*          columnGap: "10px", */}
              {/*          alignItems: "center", */}
              {/*        }} */}
              {/*      > */}
              {/*        <Box */}
              {/*          sx={{ */}
              {/*            width: "10px", */}
              {/*            height: "10px", */}
              {/*            minWidth: "10px", */}
              {/*            minHeight: "10px", */}
              {/*            borderRadius: "50%", */}
              {/*            background: "#407BFF", */}
              {/*          }} */}
              {/*        /> */}
              {/*        <span>{child.title}</span> */}
              {/*      </Box> */}
              {/*      <Box */}
              {/*        sx={{ */}
              {/*          display: "flex", */}
              {/*          flexDirection: "column", */}
              {/*          rowGap: "5px", */}
              {/*        }} */}
              {/*      > */}
              {/*        {child?.child_links?.map((linkItem, linkIndex) => ( */}
              {/*          <Box */}
              {/*            key={linkIndex} */}
              {/*            sx={{ */}
              {/*              cursor: "pointer", */}
              {/*              background: "#EFF3F8", */}
              {/*              width: "250px !important", */}
              {/*              minWidth: "250px !important", */}
              {/*              // height: "72px", */}
              {/*              borderRadius: "8px", */}
              {/*              color: "#2455A2", */}
              {/*              p: "5px", */}
              {/*              display: "flex", */}
              {/*              alignItems: "center", */}
              {/*              justifyContent: "center", */}
              {/*              // fontSize: "8px", */}
              {/*              boxSizing: "unset", */}
              {/*            }} */}
              {/*          > */}
              {/*            <div style={{ display: "flex" }}> */}
              {/*              <span> */}
              {/*                {" "} */}
              {/*                <ErrorOutlineIcon */}
              {/*                  sx={{ */}
              {/*                    fontSize: "16px", */}
              {/*                    mr: "5px", */}
              {/*                    display: "flex", */}
              {/*                    alignItems: "flex-start", */}
              {/*                  }} */}
              {/*                /> */}
              {/*              </span> */}
              {/*              <a */}
              {/*                href={linkItem.link} */}
              {/*                target="_blank" */}
              {/*                rel="noopener noreferrer" */}
              {/*                style={{ */}
              {/*                  textDecoration: "none", */}
              {/*                  color: "inherit", */}
              {/*                }} */}
              {/*              > */}
              {/*                <Typography */}
              {/*                  variant="body1" */}
              {/*                  sx={{ */}
              {/*                    fontFamily: "inherit !important", */}
              {/*                    "&:hover": { textDecoration: "underline" }, */}
              {/*                    fontSize: "12px", */}
              {/*                    textAlign: "center", */}
              {/*                  }} */}
              {/*                > */}
              {/*                  {linkItem.link_title} */}
              {/*                </Typography> */}
              {/*              </a> */}
              {/*            </div> */}
              {/*          </Box> */}
              {/*        ))} */}
              {/*      </Box> */}
              {/*    </Box> */}
              {/*  ) */}
              {/* )} */}
            </AccordionDetails>
          </Accordion>
        ))}
      </>
    );
  };

  return (
    <Container>
      <DeputyKnowWrapper>
        <h2 style={{ textAlign: "left" }}>{t("council_registry_functions")}</h2>
        <div>
          <RecursiveAccordion data={registry} />
          {/* {registry?.map((item, index) => ( */}
          {/*  <Accordion */}
          {/*    key={index} */}
          {/*    expanded={expanded === "panel" + `${index + 1}`} */}
          {/*    onChange={handleChange("panel" + `${index + 1}`)} */}
          {/*  > */}
          {/*    <AccordionSummary */}
          {/*      expandIcon={ */}
          {/*        <Box> */}
          {/*          <ConditionalIcon */}
          {/*            expanded={expanded === "panel" + `${index + 1}`} */}
          {/*          /> */}
          {/*        </Box> */}
          {/*      } */}
          {/*      aria-controls={`panel${index + 1}d-content`} */}
          {/*      id={`panel${index + 1}d-header`} */}
          {/*    > */}
          {/*      <Typography */}
          {/*        fontWeight={700} */}
          {/*        sx={{ */}
          {/*          fontFamily: "inherit !important", */}
          {/*          fontSize: "20px", */}
          {/*          color: */}
          {/*            expanded === "panel" + `${index + 1}` */}
          {/*              ? "#2455A2" */}
          {/*              : "#050F27", */}
          {/*        }} */}
          {/*      > */}
          {/*        {item.title} */}
          {/*      </Typography> */}
          {/*    </AccordionSummary> */}

          {/*    <AccordionDetails> */}
          {/*      {item?.child?.map((child) => ( */}
          {/*        <Box */}
          {/*          key={child.id} */}
          {/*          sx={{ */}
          {/*            display: "flex", */}
          {/*            justifyContent: "space-between", */}
          {/*            alignItems: "center", */}
          {/*            mb: "15px", */}
          {/*            color: "#454C5D", */}
          {/*            columnGap: "10px", */}
          {/*          }} */}
          {/*        > */}
          {/*          <Box */}
          {/*            sx={{ */}
          {/*              display: "flex", */}
          {/*              columnGap: "10px", */}
          {/*              alignItems: "center", */}
          {/*            }} */}
          {/*          > */}
          {/*            <Box */}
          {/*              sx={{ */}
          {/*                width: "10px", */}
          {/*                height: "10px", */}
          {/*                minWidth: "10px", */}
          {/*                minHeight: "10px", */}
          {/*                borderRadius: "50%", */}
          {/*                background: "#407BFF", */}
          {/*              }} */}
          {/*            /> */}
          {/*            <span>{child.title}</span> */}
          {/*          </Box> */}
          {/*          <Box */}
          {/*            sx={{ */}
          {/*              display: "flex", */}
          {/*              flexDirection: "column", */}
          {/*              rowGap: "5px", */}
          {/*            }} */}
          {/*          > */}
          {/*            {child?.child_links?.map((linkItem, linkIndex) => ( */}
          {/*              <Box */}
          {/*                key={linkIndex} */}
          {/*                sx={{ */}
          {/*                  cursor: "pointer", */}
          {/*                  background: "#EFF3F8", */}
          {/*                  width: "250px !important", */}
          {/*                  minWidth: "250px !important", */}
          {/*                  // height: "72px", */}
          {/*                  borderRadius: "8px", */}
          {/*                  color: "#2455A2", */}
          {/*                  p: "5px", */}
          {/*                  display: "flex", */}
          {/*                  alignItems: "center", */}
          {/*                  justifyContent: "center", */}
          {/*                  // fontSize: "8px", */}
          {/*                  boxSizing: "unset", */}
          {/*                }} */}
          {/*              > */}
          {/*                <div style={{ display: "flex" }}> */}
          {/*                  <span> */}
          {/*                    {" "} */}
          {/*                    <ErrorOutlineIcon */}
          {/*                      sx={{ */}
          {/*                        fontSize: "16px", */}
          {/*                        mr: "5px", */}
          {/*                        display: "flex", */}
          {/*                        alignItems: "flex-start", */}
          {/*                      }} */}
          {/*                    /> */}
          {/*                  </span> */}
          {/*                  <a */}
          {/*                    href={linkItem.link} */}
          {/*                    target="_blank" */}
          {/*                    rel="noopener noreferrer" */}
          {/*                    style={{ */}
          {/*                      textDecoration: "none", */}
          {/*                      color: "inherit", */}
          {/*                    }} */}
          {/*                  > */}
          {/*                    <Typography */}
          {/*                      variant="body1" */}
          {/*                      sx={{ */}
          {/*                        fontFamily: "inherit !important", */}
          {/*                        "&:hover": { textDecoration: "underline" }, */}
          {/*                        fontSize: "12px", */}
          {/*                        textAlign: "center", */}
          {/*                      }} */}
          {/*                    > */}
          {/*                      {linkItem.link_title} */}
          {/*                    </Typography> */}
          {/*                  </a> */}
          {/*                </div> */}
          {/*              </Box> */}
          {/*            ))} */}
          {/*          </Box> */}
          {/*        </Box> */}
          {/*      ))} */}
          {/*    </AccordionDetails> */}
          {/*  </Accordion> */}
          {/* ))} */}
        </div>
      </DeputyKnowWrapper>
    </Container>
  );
};

export default RegistryPage;
