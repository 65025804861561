import { httpGet } from "../index";
import { IRespBody } from "../types/resp-body/resp-body";
import { PaginationResp } from "../types/pagination";

export interface RegistriesClientParam {
  size?: number;
  page?: number;
  lang?: string;
}

export interface IRegistriesChildSecond {
  link: string;
  link_title: string;
}

export interface IRegistriesChildFirst {
  id: number;
  title: string;
  child_links: IRegistriesChildSecond[];
}

export interface IRegistries {
  id: number;
  title: string;
  child: IRegistriesChildFirst[];
}

export interface RegistriesClientResp extends PaginationResp {
  items: IRegistries[];
}

export interface IRecurringRegistryResp {
  id: number;
  title: string;
  child: IRecurringRegistryResp[] | [];
  child_links: IRegistriesChildSecond[] | [];
  is_last: boolean;
}

const registries = {
  index: async (params: RegistriesClientParam) =>
    await httpGet<IRespBody<IRecurringRegistryResp[]>>({
      url: "/client/registries",
      params,
    }),
};

export default registries;
