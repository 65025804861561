import { styled } from "@mui/material/styles";

export const ManuWrapper = styled("div")`
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  background: #2455a2;
  padding: 115px 70px;
  opacity: 1;
  transition: 0.3s;
  &.close {
    opacity: 0;
    transform: translateY(-100vh);
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 3%;
    z-index: -1;
    overflow: hidden;
  }
  .content {
    display: flex;
    width: 100%;
    .links {
      display: flex;
      flex-wrap: wrap;
      flex: 1;

      a {
        width: 33.33%;
        font-weight: 600;
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
        margin-bottom: 20px;
        text-decoration: none;
      }
    }
  }
`;
