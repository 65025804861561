import { httpGet, httpPost } from "../index";
import {
  AuthEriLoginParam,
  AuthLoginParam,
} from "../types/dashboard/auth/auth-login";
import { IRespBody } from "../types/resp-body/resp-body";
import { IToken } from "../../interfaces/dashboard/auth/token";
import { IMeInfo } from "../../interfaces/dashboard/auth/me-info";

const auth = {
  login: async (params: AuthLoginParam) =>
    await httpPost<IRespBody<IToken>>({ url: "/dashboard/auth/login", params }),
  loginEri: async (data: AuthEriLoginParam) =>
    await httpPost<IRespBody<IToken>>({
      url: "/dashboard/auth/login-eri",
      data,
    }),
  me: async (lang?: string) =>
    await httpGet<IRespBody<IMeInfo>>({
      url: "/dashboard/auth/me",
      params: { lang },
    }),
  logout: async () => await httpPost({ url: "/dashboard/auth/logout" }),
  refresh: async () =>
    await httpPost<IRespBody<IToken>>({ url: "/dashboard/auth/refresh" }),
};

export default auth;
