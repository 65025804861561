interface RoleIds {
  ADMIN: 1; // Админ
  GUEST: 2;
  EXPERT: 3;
  COUNCIL: 4;
  BOARD_MANAGER: 5;
  BOARD_MANAGER_DEPUTY: 6;
  SENATOR: 7;
  DEPUTY: 9;
  JURIST: 10;
  MONITORING_ADM: 11;
  MONITORING_SENATE: 12;
  MONITORING_REGION: 13;
}
/**
 * ADMIN => Админ
 * GUEST => Гость
 * EXPERT => Бош мутахассис
 * COUNCIL => Кенгаш котибияти мудири
 * BOARD_MANAGER => Кенгаш раиси
 * BOARD_MANAGER_DEPUTY => Кенгаш раиси ўринбосари
 * SENATOR => Сенатор
 * DEPUTY => Депутат
 * JURIST => Юрист
 * MONITORING_ADM => Администрация мониторинга
 * MONITORING_SENATE => Сенат мониторинга
 * MONITORING_REGION => Регион мониторинга
 */

export const rolesIds: RoleIds = {
  ADMIN: 1,
  GUEST: 2,
  EXPERT: 3,
  COUNCIL: 4,
  BOARD_MANAGER: 5,
  BOARD_MANAGER_DEPUTY: 6,
  SENATOR: 7,
  DEPUTY: 9,
  JURIST: 10,
  MONITORING_ADM: 11,
  MONITORING_SENATE: 12,
  MONITORING_REGION: 13,
};

interface StatusesIds {
  DRAFT: 1;
  AGREEMENT: 2;
  IN_EXECUTION: 3;
  FOR_SIGNATURE: 5;
  SIGNED: 6;
  SEND: 7;
  ANSWER: 8;
  APPROVE_EXECUTION: 9;
  EXECUTION: 10;
  IN_ACCOUNTANT: 17;
  REGISTRATION: 19;
  CANCEL: 18;
  BASKET: 23;
  IN_COUNCIL: 24;
  RETRY_PROCESSING: 25;
}
/**
 * DRAFT => Қоралама
 * AGREEMENT => Келишув жараёнида
 * IN_EXECUTION => Ижро жараёнида
 * FOR_SIGNATURE => Имзога киритилган
 * SIGNED => Имзоланган
 * SEND => Юборилган
 * ANSWER => Жавоб келган
 * APPROVE_EXECUTION => Ijroni tasdqilash
 * EXECUTION => Ijro etilgan
 * IN_ACCOUNTANT => Бош ҳисобчида
 * REGISTRATION => Ройхатга олиш
 * CANCEL => Рад этилган
 * IN_COUNCIL => Kotibyat mudirida
 * RETRY_PROCESSING => qayta ishlashda
 **/

export const statusesIds: StatusesIds = {
  DRAFT: 1,
  AGREEMENT: 2,
  IN_EXECUTION: 3,
  FOR_SIGNATURE: 5,
  SIGNED: 6,
  SEND: 7,
  ANSWER: 8,
  APPROVE_EXECUTION: 9,
  EXECUTION: 10,
  IN_ACCOUNTANT: 17,
  REGISTRATION: 19,
  CANCEL: 18,
  BASKET: 23,
  IN_COUNCIL: 24,
  RETRY_PROCESSING: 25,
};

export type RELEASE_IN_PRACTICE = 0;
export type RELEASE_CANCELLED = 6;
export type RELEASE_EDITED = 7;
export type RELEASE_FOUND_TO_BE_INVALID = 200;

export type Releases =
  | RELEASE_IN_PRACTICE
  | RELEASE_CANCELLED
  | RELEASE_EDITED
  | RELEASE_FOUND_TO_BE_INVALID;

export interface ReleaseStatuses {
  RELEASE_IN_PRACTICE: RELEASE_IN_PRACTICE;
  RELEASE_CANCELLED: RELEASE_CANCELLED;
  RELEASE_EDITED: RELEASE_EDITED;
  RELEASE_FOUND_TO_BE_INVALID: RELEASE_FOUND_TO_BE_INVALID;
}

export const releaseStatuses: ReleaseStatuses = {
  RELEASE_IN_PRACTICE: 0,
  RELEASE_CANCELLED: 6,
  RELEASE_EDITED: 7,
  RELEASE_FOUND_TO_BE_INVALID: 200,
};

export const periodList = [
  {
    title: "today",
    value: "1",
  },
  {
    title: "current_month",
    value: "2",
  },
  {
    title: "oraliq",
    value: "3",
  },
  {
    title: "st1_quarter",
    value: "4",
  },
  {
    title: "st2_quarter",
    value: "5",
  },
  {
    title: "st3_quarter",
    value: "6",
  },
  {
    title: "st4_quarter",
    value: "7",
  },
];
