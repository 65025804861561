import React, { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";

const MainMonitoringPage = lazy(
  async () => await import("../pages/dashboard/monitoring/main")
);
const CompositionCouncils = lazy(
  async () => await import("../pages/dashboard/monitoring/composition-councils")
);
const UserProfilePage = lazy(
  async () => await import("../pages/dashboard/monitoring/profile")
);
const MonitoringReports = lazy(
  async () => await import("../pages/dashboard/monitoring/reports")
);
const MonitoringDocListPage = lazy(
  async () => await import("../pages/dashboard/monitoring/doc/list")
);
const MonitoringDocShowPage = lazy(
  async () => await import("../pages/dashboard/monitoring/doc/show")
);
const QualityComposition = lazy(
  async () => await import("../pages/dashboard/monitoring/quality-composition")
);
const CouncilDesign = lazy(
  async () => await import("../pages/dashboard/monitoring/council-design")
);
const DeputyRequest = lazy(
  async () => await import("../pages/dashboard/monitoring/deputy-request")
);
const InstructionsPage = lazy(
  async () => await import("../pages/dashboard/instructions")
);

export const MONITORING_SENATE_ROUTE: RouteObject[] = [
  {
    path: "monitoring",
    children: [
      {
        path: "doc",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <MonitoringDocListPage />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <MonitoringDocShowPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "main",
        element: (
          <Suspense>
            <MainMonitoringPage />
          </Suspense>
        ),
      },
      {
        path: "composition-councils",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <CompositionCouncils />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <UserProfilePage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "quality-composition",
        element: (
          <Suspense>
            <QualityComposition />
          </Suspense>
        ),
      },
      {
        path: "reports",
        element: (
          <Suspense>
            <MonitoringReports />
          </Suspense>
        ),
      },
      {
        path: "council-decision",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <CouncilDesign />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <MonitoringDocShowPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "deputy-request",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <DeputyRequest />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <MonitoringDocShowPage />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "instructions",
    element: (
      <Suspense>
        <InstructionsPage />
      </Suspense>
    ),
  },
];
