import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";

interface AppBarProps {
  open?: boolean;
  drawerwidth: number;
}

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})<{
  open?: boolean;
  drawerwidth: number;
}>(({ theme, open, drawerwidth }) => ({
  "&.fadeIn": {
    animation: "0.5s fadeIn forwards",
  },

  "&.fadeOut": {
    animation: "0.5s fadeOut forwards",
  },

  "@keyframes fadeIn": {
    from: {
      opacity: 0,
      transform: "translate(-20px, 0)",
    },
    to: {
      opacity: 1,
      transform: "translate(0px, 0px)",
    },
  },

  "@keyframes fadeOut": {
    from: {
      opacity: 1,
      transform: "translate(0px, 0px)",
    },
    to: {
      transform: "translate(-20px, 0)",
      opacity: 0,
    },
  },

  minHeight: "calc(100vh - 40px)",
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerwidth}px`,
  ...(open === true && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
  paddingTop: "100px",
}));

export const AppBar = styled(MuiAppBar)<AppBarProps>(
  ({ theme, open, drawerwidth }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open === true && {
      width: `calc(100% - ${drawerwidth}px)`,
      marginLeft: `${drawerwidth}px`,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    backgroundColor: "black",
  })
);

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "center",
  minHeight: "initial !important",
  button: {
    color: "#fff",
  },
}));
