import { NavLink, To, useLocation } from "react-router-dom";
import { Badge, Box, Fade } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { RightArrowSvg } from "../../../../assets/media/svg-icons";
import { NavProps } from "../../../../interfaces/utility";

export const NavLinks: React.FC<NavProps> = ({
  link,
  icon,
  title,
  children,
  end,
  count,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const currentParentUrl = location.pathname
    .split("/")
    .find((item: string) => item === link);
  const [checked, setChecked] = useState<boolean>(!(currentParentUrl == null));

  return children !== undefined ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          padding: "0.3em 0 0.3em 1.25em",
          textDecoration: "none",
          transition: ".3s",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "#ffffff",

          "&:hover, &:focus, &.active": {
            // color: '#1C295C',
            // backgroundColor: '#AEF8E4',
            cursor: "pointer",
          },
        }}
        className={checked ? "active" : ""}
        onClick={() => {
          setChecked(!checked);
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            alignItems: "center",
          }}
        >
          {Boolean(icon) && (
            <Box
              sx={{
                // width: '45px',
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: "1em",
              }}
            >
              {icon}
            </Box>
          )}
          <span>{t(title)}</span>
          <Badge
            badgeContent={count}
            color="primary"
            sx={{ marginLeft: "20px" }}
          />
        </Box>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            transition: ".3s",
            transform: `rotate(${checked ? 90 : 0}deg)`,
            marginRight: "10px",
          }}
        >
          <RightArrowSvg />
        </Box>
      </Box>
      <Fade in={checked} mountOnEnter unmountOnExit>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: "1.25em",
          }}
        >
          {children.map(
            (
              item: { link: To; title: any; count?: number },
              index: React.Key | null | undefined
            ) => (
              <NavLink to={item.link} key={index} end={end}>
                <>
                  {t(item.title)}
                  <Badge
                    badgeContent={item.count}
                    color="warning"
                    sx={{ marginLeft: "20px" }}
                  />
                </>
              </NavLink>
            )
          )}
        </Box>
      </Fade>
    </Box>
  ) : (
    <NavLink style={{ marginBottom: "10px" }} to={link} end={end}>
      <>
        {Boolean(icon) && (
          <Box
            sx={{
              // width: '45px',
              display: "inline-flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            {icon}
          </Box>
        )}
        <span>{t(title)}</span>
        <Badge
          badgeContent={count}
          color="primary"
          sx={{ marginLeft: "20px" }}
        />
      </>
    </NavLink>
  );
};
