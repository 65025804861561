import { NavProps } from "../interfaces/utility";
import { useStore } from "effector-react";
import { $account } from "../model/account";
import { rolesIds } from "../share";
import { AdminCategories } from "./admin-categories";
import { IEventGetData } from "../services/types/socket";
import { ExpertCategories } from "./expert-categories";
import { JuristCategories } from "./jurist-categories";
import { CouncilCategories } from "./council-categories";
import { DeputyCategories } from "./deputy-categories";
import { BoardManagerDeputyCategories } from "./board-manager-deputy-categories";
import { SenatorCategories } from "./senator-categories";
import { BoardManagerCategories } from "./board-manager-categories";
import { MonitoringRegionCategories } from "./monitoring-region-categories";
import { MonitoringSenateCategories } from "./monitoring-senate-categories";
import { MonitoringAdmCategories } from "./monitoring-adm-categories";

export const getCatRoutes = (badgeNotice: IEventGetData | null): NavProps[] => {
  const userData = useStore($account).$accountData.data;

  if (userData?.role_id === rolesIds.ADMIN) {
    return AdminCategories(badgeNotice);
  } else if (userData?.role_id === rolesIds.EXPERT) {
    // bosh mutaxassis
    return ExpertCategories(badgeNotice);
  } else if (userData?.role_id === rolesIds.JURIST) {
    // yurist
    return JuristCategories(badgeNotice);
  } else if (userData?.role_id === rolesIds.COUNCIL) {
    // kotibiyat mudiri
    return CouncilCategories(badgeNotice);
  } else if (userData?.role_id === rolesIds.DEPUTY) {
    // Deputat
    return DeputyCategories(badgeNotice);
  } else if (userData?.role_id === rolesIds.BOARD_MANAGER_DEPUTY) {
    return BoardManagerDeputyCategories(badgeNotice);
  } else if (userData?.role_id === rolesIds.SENATOR) {
    return SenatorCategories(badgeNotice);
  } else if (userData?.role_id === rolesIds.BOARD_MANAGER) {
    return BoardManagerCategories(badgeNotice);
  } else if (userData?.role_id === rolesIds.MONITORING_REGION) {
    return MonitoringRegionCategories;
  } else if (userData?.role_id === rolesIds.MONITORING_SENATE) {
    return MonitoringSenateCategories;
  } else if (userData?.role_id === rolesIds.MONITORING_ADM) {
    return MonitoringAdmCategories;
  }

  return [];
};
