import { ADMIN_ROUTE } from "./admin";
import { JURIST_ROUTE } from "./jurist";

import { RouteObject } from "react-router-dom";
import { rolesIds } from "../share";
import { COUNCIL_ROUTE } from "./council";
import { BOARD_MANAGER_ROUTE } from "./board-manager";
import { DEPUTY_ROUTE } from "./deputy";
import { BOARD_MANAGER_DEPUTY_ROUTE } from "./board-manager-deputy";
import { SENATOR_ROUTE } from "./senator";
import { EXPERT_ROUTE } from "./expert";
import { MONITORING_ADM_ROUTE } from "./monitoring-adm";
import { MONITORING_SENATE_ROUTE } from "./monitoring-senate";
import { MONITORING_REGION_ROUTE } from "./monitoring-region";

export function ROUTES(userRoleId?: number): RouteObject[] {
  if (userRoleId === rolesIds.ADMIN) {
    return ADMIN_ROUTE;
  } else if (userRoleId === rolesIds.EXPERT) {
    // bosh mutaxassis
    return EXPERT_ROUTE;
  } else if (userRoleId === rolesIds.JURIST) {
    // yurist
    return JURIST_ROUTE;
  } else if (userRoleId === rolesIds.COUNCIL) {
    // kotibiyat mudiri
    return COUNCIL_ROUTE;
  } else if (userRoleId === rolesIds.BOARD_MANAGER) {
    // kengash raisi
    return BOARD_MANAGER_ROUTE;
  } else if (userRoleId === rolesIds.DEPUTY) {
    // Deputat
    return DEPUTY_ROUTE;
  } else if (userRoleId === rolesIds.BOARD_MANAGER_DEPUTY) {
    // kengash raisi orinbosari
    return BOARD_MANAGER_DEPUTY_ROUTE;
  } else if (userRoleId === rolesIds.SENATOR) {
    // senator
    return SENATOR_ROUTE;
  } else if (userRoleId === rolesIds.MONITORING_ADM) {
    return MONITORING_ADM_ROUTE;
  } else if (userRoleId === rolesIds.MONITORING_REGION) {
    return MONITORING_REGION_ROUTE;
  } else if (userRoleId === rolesIds.MONITORING_SENATE) {
    return MONITORING_SENATE_ROUTE;
  } else {
    return [];
  }
}
