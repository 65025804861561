import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// @ts-expect-error
import { install } from "ga-gtag";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

install("G-W0YM3XGT6X");

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
