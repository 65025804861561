import * as React from "react";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";

import Collapse from "@mui/material/Collapse";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function SideMenuLegislation(): JSX.Element {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const handleClick = (): void => {
    setOpen(!open);
  };

  return (
    <List
      sx={{
        position: "absolute",
        width: "100%",
        maxWidth: 360,
        bgcolor: "#023188",
        color: "#fff",
        borderRadius: "8px",
        transition: "opacity 1s",
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton>
        {/* <ListItemText primary="Sent mail" /> */}
        <a
          style={{ color: "#fff" }}
          href="https://lex.uz/docs/-6445145"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("uzb_constitution")}
        </a>
      </ListItemButton>

      <ListItemButton onClick={handleClick}>
        <Link style={{ color: "#fff" }} to="#">
          {t("uzb_legislation")}
        </Link>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }}>
            <a
              style={{ color: "#fff" }}
              href="https://lex.uz/docs/-436950"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("about_deputy")}
            </a>
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }}>
            <a
              style={{ color: "#fff" }}
              href="https://lex.uz/docs/112170"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("about_local")}
            </a>
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
