import {
  AddDocumentSvg,
  CalendarTableSvg,
  CheckDocumentSvg,
  InternalDocumentSvg,
  OutDocumentSvg,
} from "../assets/media/svg-icons";
import React from "react";
import { IEventGetData } from "../services/types/socket";
import { NavProps } from "../interfaces/utility";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

export const DeputyCategories = (
  badgeNotice: IEventGetData | null
): NavProps[] => {
  return [
    {
      title: "home_page",
      link: "/dashboard",
      end: true,
    },
    {
      title: "add_new_document",
      link: "new-doc",
      icon: <AddDocumentSvg />,
    },
    {
      title: "incoming",
      link: "in-doc",
      count: badgeNotice?.incoming_count_all ?? undefined,
      icon: <CheckDocumentSvg />,
      children: [
        {
          title: "my_docs",
          link: "in-doc/my-docs",
          count: badgeNotice?.incoming_count_my_documents ?? undefined,
        },
        {
          title: "execution",
          link: "in-doc/execution",
          count: badgeNotice?.incoming_count_execution ?? undefined,
        },
        {
          title: "confirmation",
          link: "in-doc/confirmation",
          count: badgeNotice?.incoming_count_confirmation ?? undefined,
        },
        {
          title: "executed",
          link: "in-doc/executed",
          count: badgeNotice?.incoming_count_executed ?? undefined,
        },
        {
          title: "all_docs",
          link: "in-doc/all-docs",
          count: badgeNotice?.incoming_count_all ?? undefined,
        },
      ],
    },
    {
      title: "outgoing_documents",
      link: "out-doc",
      count: badgeNotice?.outgoing_count_all ?? undefined,
      icon: <OutDocumentSvg />,
      children: [
        {
          title: "draft",
          link: "out-doc/draft",
          count: badgeNotice?.outgoing_count_draft ?? undefined,
        },
        {
          title: "for_signature",
          link: "out-doc/for-signature",
          count: badgeNotice?.outgoing_count_signing ?? undefined,
        },
        {
          title: "agreement",
          link: "out-doc/agreement",
          count: badgeNotice?.outgoing_count_agreement ?? undefined,
        },
        {
          title: "confirmed",
          link: "out-doc/confirmed",
          count: badgeNotice?.outgoing_count_approved ?? undefined,
        },
        {
          title: "rejected",
          link: "out-doc/rejected",
          count: badgeNotice?.outgoing_count_rejected ?? undefined,
        },
        {
          title: "retry_processing",
          link: "out-doc/retry-processing",
          count: badgeNotice?.outgoing_count_processing ?? undefined,
        },
        {
          title: "registration",
          link: "out-doc/registration",
          count: badgeNotice?.outgoing_count_approved ?? undefined,
        },
        {
          title: "all_docs",
          link: "out-doc/all-docs",
          count: badgeNotice?.outgoing_count_all ?? undefined,
        },
      ],
    },
    {
      title: "internal_doc",
      link: "internal-doc",
      count: badgeNotice?.internal_count_all ?? undefined,
      icon: <InternalDocumentSvg />,
      children: [
        {
          title: "draft",
          link: "internal-doc/draft",
          count: badgeNotice?.internal_count_draft ?? undefined,
        },
        {
          title: "for_signature",
          link: "internal-doc/for-signature",
          count: badgeNotice?.internal_count_signing ?? undefined,
        },
        {
          title: "agreement",
          link: "internal-doc/agreement",
          count: badgeNotice?.internal_count_agreement ?? undefined,
        },
        {
          title: "confirmed",
          link: "internal-doc/confirmed",
          count: badgeNotice?.internal_count_approved ?? undefined,
        },
        {
          title: "rejected",
          link: "internal-doc/rejected",
          count: badgeNotice?.internal_count_reject ?? undefined,
        },
        {
          title: "all_docs",
          link: "internal-doc/all-docs",
          count: badgeNotice?.internal_count_all ?? undefined,
        },
        {
          title: "basket",
          link: "internal-doc/basket",
          count: badgeNotice?.internal_count_basket ?? undefined,
        },
      ],
    },
    {
      title: "useful_info",
      link: "useful-info",
      icon: <NewspaperIcon />,
      children: [
        {
          title: "juridical_advice",
          link: "useful-info/juridical-advice",
        },
        {
          title: "useful",
          link: "useful-info/hyperlinks",
        },
        {
          title: "ministries_and_agencies",
          link: "useful-info/ministries-and-agencies",
        },
        // {
        //   title: "neighborhood",
        //   link: "useful-info/neighborhood",
        // },
      ],
    },
    {
      title: "notifications",
      link: "notifications",
      icon: <NotificationsActiveIcon />,
      count: badgeNotice?.note_count_all ?? undefined,
    },
    {
      title: "deputy_table",
      link: "deputy-table",
      icon: <CalendarTableSvg />,
    },
    {
      title: "instructions",
      link: "instructions",
      icon: <CheckDocumentSvg />,
    },
  ];
};
