import React, { lazy, Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";
import InBoxAppointmentList from "../pages/dashboard/in-box/appointment";

const NewDocPage = lazy(
  async () => await import("../pages/dashboard/docs-page/new-doc")
);
const CreateOutBoxLetterDoc = lazy(
  async () => await import("../pages/dashboard/create-new-doc/out-box/letter")
);
const CreateOutBoxDeputyRequestDoc = lazy(
  async () =>
    await import("../pages/dashboard/create-new-doc/out-box/deputy-request")
);
const CreateInBoxLetterDoc = lazy(
  async () => await import("../pages/dashboard/create-new-doc/in-box/letter")
);
const CreateInBoxAppointmentDoc = lazy(
  async () =>
    await import("../pages/dashboard/create-new-doc/in-box/appointment")
);
const CreateInBoxRequestDoc = lazy(
  async () => await import("../pages/dashboard/create-new-doc/in-box/request")
);
// const CreateReport = lazy(
//   async () => await import("../pages/dashboard/create-new-doc/report")
// );
const OutBoxDocs = lazy(
  async () => await import("../pages/dashboard/out-box/list")
);
const ShowDoc = lazy(
  async () => await import("../pages/dashboard/out-box/show-doc")
);
const EditDoc = lazy(
  async () => await import("../pages/dashboard/out-box/edit-doc")
);
const EditOutBoxDoc = lazy(
  async () => await import("../pages/dashboard/out-box/edit")
);

const CreateProtocolPage = lazy(
  async () =>
    await import("../pages/dashboard/create-new-doc/internal/protocols")
);
const CreateDecreesPage = lazy(
  async () => await import("../pages/dashboard/create-new-doc/internal/decrees")
);
// const CreateCommandDocPage = lazy(
//   async () => await import("../pages/dashboard/create-new-doc/internal/command")
// );
const InternalDocs = lazy(
  async () => await import("../pages/dashboard/internal-box/list")
);
const InternalEdit = lazy(
  async () => await import("../pages/dashboard/internal-box/edit")
);

const InternalShowDoc = lazy(
  async () => await import("../pages/dashboard/internal-box/show-doc")
);
const InternalEditDoc = lazy(
  async () => await import("../pages/dashboard/internal-box/edit-doc")
);
// const ReportsListPage = lazy(
//   async () => await import("../pages/dashboard/report/list")
// );
// const ReportDocUpdatePage = lazy(
//   async () => await import("../pages/dashboard/report/edit")
// );
// const ReportsShowPage = lazy(
//   async () => await import("../pages/dashboard/report/show")
// );
const InBoxDocs = lazy(
  async () => await import("../pages/dashboard/in-box/list")
);
const InboxShowDoc = lazy(
  async () => await import("../pages/dashboard/in-box/show-doc")
);

const AppoinmentShow = lazy(
  async () => await import("../pages/dashboard/in-box/appointment/show")
);

const InboxEditDoc = lazy(
  async () => await import("../pages/dashboard/in-box/edit")
);
const EditInDoc = lazy(
  async () => await import("../pages/dashboard/in-box/edit-doc")
);

const NewsCreate = lazy(
  async () => await import("../pages/dashboard/news/create")
);
const NewsEdit = lazy(async () => await import("../pages/dashboard/news/edit"));
const NewsListPage = lazy(
  async () => await import("../pages/dashboard/news/list")
);
const ActionsListPage = lazy(
  async () =>
    await import("../pages/dashboard/settings/staff/actions-history/list")
);
const ActionShowPage = lazy(
  async () =>
    await import("../pages/dashboard/settings/staff/actions-history/show")
);
const SessionCreate = lazy(
  async () => await import("../pages/dashboard/session/create")
);
const SessionEdit = lazy(
  async () => await import("../pages/dashboard/session/edit")
);
const SessionsListPage = lazy(
  async () => await import("../pages/dashboard/session/list")
);
const CouncilStaffPage = lazy(
  async () => await import("../pages/dashboard/settings/staff/council-staff")
);
const CouncilStaffAdd = lazy(
  async () =>
    await import("../pages/dashboard/settings/staff/council-staff-add")
);
const StandingCommissions = lazy(
  async () =>
    await import("../pages/dashboard/settings/standing-commissions/list")
);
const StandingCommissionsAddPage = lazy(
  async () =>
    await import("../pages/dashboard/settings/standing-commissions/add")
);
const StandingCommissionsEditPage = lazy(
  async () =>
    await import("../pages/dashboard/settings/standing-commissions/edit")
);
const PositionsPage = lazy(
  async () => await import("../pages/dashboard/settings/positions/list")
);
const CreatePositionPage = lazy(
  async () => await import("../pages/dashboard/settings/positions/add")
);
const CountiesPage = lazy(
  async () => await import("../pages/dashboard/settings/counties/list")
);
const CreateCountyPage = lazy(
  async () => await import("../pages/dashboard/settings/counties/add")
);
const ContactInfoPage = lazy(
  async () => await import("../pages/dashboard/settings/contacts")
);

const DeputyCountPage = lazy(
  async () => await import("../pages/dashboard/settings/deputy-count")
);
const StaffPage = lazy(
  async () => await import("../pages/dashboard/settings/staff/staff")
);
const NotificationsListPage = lazy(
  async () => await import("../pages/dashboard/notifications/list")
);
const HyperlinksPage = lazy(
  async () => await import("../pages/dashboard/useful/hyperlinks")
);
const NeighborhoodPage = lazy(
  async () => await import("../pages/dashboard/useful/neighborhood")
);
const JuridicalAdviceListCategoryPage = lazy(
  async () =>
    await import("../pages/dashboard/useful/juridical-advice/categories-list")
);
const JuridicalAdviceLShowPage = lazy(
  async () => await import("../pages/dashboard/useful/juridical-advice/show")
);
const Agencies = lazy(
  async () =>
    await import(
      "../pages/dashboard/useful/ministries-and-agencies/templates/agencies"
    )
);
const JuridicalAdviceMainPage = lazy(
  async () => await import("../pages/dashboard/useful/juridical-advice")
);
const MinistriesAndAgencies = lazy(
  async () => await import("../pages/dashboard/useful/ministries-and-agencies")
);
const InstructionsPage = lazy(
  async () => await import("../pages/dashboard/instructions")
);

const DeputyTableListPage = lazy(
  async () => await import("../pages/dashboard/settings/deputy-table")
);

const DeputyTableAdd = lazy(
  async () =>
    await import("../pages/dashboard/settings/deputy-table/deputy-table-add")
);
const DeputyTableEdit = lazy(
  async () =>
    await import("../pages/dashboard/settings/deputy-table/deputy-table-edit")
);
const FilePage = lazy(async () => await import("../pages/dashboard/file"));

const MonitoringReports = lazy(
  async () => await import("../pages/dashboard/monitoring/reports")
);

export const EXPERT_ROUTE: RouteObject[] = [
  {
    path: "file",
    children: [
      {
        path: ":code",
        element: (
          <Suspense>
            <FilePage />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "new-doc",
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <NewDocPage />
          </Suspense>
        ),
      },
      {
        path: "contact-letter",
        element: (
          <Suspense>
            <CreateInBoxLetterDoc />
          </Suspense>
        ),
      },
      {
        path: "appeal",
        element: (
          <Suspense>
            <CreateInBoxRequestDoc />
          </Suspense>
        ),
      },
      {
        path: "appointment",
        element: (
          <Suspense>
            <CreateInBoxAppointmentDoc />
          </Suspense>
        ),
      },
      {
        path: "transmittal-later",
        element: (
          <Suspense>
            <CreateOutBoxLetterDoc />
          </Suspense>
        ),
      },
      {
        path: "deputy-request",
        element: (
          <Suspense>
            <CreateOutBoxDeputyRequestDoc />
          </Suspense>
        ),
      },
      {
        path: "decrees",
        element: (
          <Suspense>
            <CreateDecreesPage />
          </Suspense>
        ),
      },
      {
        path: "protocols",
        element: (
          <Suspense>
            <CreateProtocolPage />
          </Suspense>
        ),
      },
      // {
      //   path: "command",
      //   element: (
      //     <Suspense>
      //       <CreateCommandDocPage />
      //     </Suspense>
      //   ),
      // },

      // {
      //   path: "report",
      //   element: (
      //     <Suspense>
      //       <CreateReport />
      //     </Suspense>
      //   ),
      // },
    ],
  },
  {
    path: "in-doc",
    children: [
      {
        index: true,
        element: <Navigate to="draft" />,
      },
      {
        path: "draft",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InboxShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InboxEditDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditInDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },

      {
        path: "executed",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InboxShowDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },

      {
        path: "execution",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InboxShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InboxEditDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditInDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "confirmation",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InboxShowDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },

      {
        path: "for-signature",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InboxShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InboxEditDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditInDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "appointment",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxAppointmentList />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <AppoinmentShow />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <CreateInBoxAppointmentDoc />
                  </Suspense>
                ),
              },
              // {
              //   path: ":docId",
              //   element: (
              //     <Suspense>
              //       <p>fds</p>
              //     </Suspense>
              //   ),
              // },
            ],
          },
        ],
      },
      {
        path: "all-docs",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "internal-doc",
    children: [
      {
        index: true,
        element: <Navigate to="draft" />,
      },

      {
        path: "draft",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InternalShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InternalEdit />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <InternalEditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "for-signature",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InternalShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InternalEdit />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <InternalEditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "agreement",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <InternalShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "confirmed",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <InternalShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "rejected",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <InternalShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "retry-processing",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <InternalShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "in-council",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InternalShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InternalEdit />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <InternalEditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "all-docs",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "basket",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InternalDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <InternalShowDoc />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "out-doc",
    children: [
      { index: true, element: <Navigate to="draft" /> },

      {
        path: "draft",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditOutBoxDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },

      {
        path: "for-signature",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditOutBoxDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "agreement",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <ShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "confirmed",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <ShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "rejected",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditOutBoxDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "retry-processing",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditOutBoxDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "all-docs",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  // {
  //   path: "report",
  //   children: [
  //     {
  //       index: true,
  //       element: <Navigate to="draft" />,
  //     },
  //     {
  //       path: "draft",
  //       children: [
  //         {
  //           index: true,
  //           element: (
  //             <Suspense>
  //               <ReportsListPage />
  //             </Suspense>
  //           ),
  //         },
  //         {
  //           path: ":id",
  //           children: [
  //             {
  //               index: true,
  //               element: (
  //                 <Suspense>
  //                   <ReportsShowPage />
  //                 </Suspense>
  //               ),
  //             },
  //             {
  //               path: "edit",
  //               element: (
  //                 <Suspense>
  //                   <ReportDocUpdatePage />
  //                 </Suspense>
  //               ),
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       path: "confirmed",
  //       children: [
  //         {
  //           index: true,
  //           element: (
  //             <Suspense>
  //               <ReportsListPage />
  //             </Suspense>
  //           ),
  //         },
  //         {
  //           path: ":id",
  //           children: [
  //             {
  //               index: true,
  //               element: (
  //                 <Suspense>
  //                   <ReportsShowPage />
  //                 </Suspense>
  //               ),
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       path: "for-signature",
  //       children: [
  //         {
  //           index: true,
  //           element: (
  //             <Suspense>
  //               <ReportsListPage />
  //             </Suspense>
  //           ),
  //         },
  //         {
  //           path: ":id",
  //           children: [
  //             {
  //               index: true,
  //               element: (
  //                 <Suspense>
  //                   <ReportsShowPage />
  //                 </Suspense>
  //               ),
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     {
  //       path: "all-docs",
  //       children: [
  //         {
  //           index: true,
  //           element: (
  //             <Suspense>
  //               <ReportsListPage />
  //             </Suspense>
  //           ),
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: "reports",
    element: (
      <Suspense>
        <MonitoringReports />
      </Suspense>
    ),
  },
  {
    path: "news",
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <NewsListPage />
          </Suspense>
        ),
      },
      {
        path: "create",
        element: (
          <Suspense>
            <NewsCreate />
          </Suspense>
        ),
      },
      {
        path: ":id",
        element: (
          <Suspense>
            <NewsEdit />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "notifications",
    element: (
      <Suspense>
        <NotificationsListPage />
      </Suspense>
    ),
  },
  {
    path: "useful-info",
    children: [
      {
        path: "hyperlinks",
        element: (
          <Suspense>
            <HyperlinksPage />
          </Suspense>
        ),
      },
      {
        path: "neighborhood",
        element: (
          <Suspense>
            <NeighborhoodPage />
          </Suspense>
        ),
      },
      {
        path: "juridical-advice",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <JuridicalAdviceMainPage />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <JuridicalAdviceListCategoryPage />
                  </Suspense>
                ),
              },
              {
                path: ":showId",
                element: (
                  <Suspense>
                    <JuridicalAdviceLShowPage />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "ministries-and-agencies",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <MinistriesAndAgencies />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <Agencies />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "sessions",
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <SessionsListPage />
          </Suspense>
        ),
      },
      {
        path: "create",
        element: (
          <Suspense>
            <SessionCreate />
          </Suspense>
        ),
      },
      {
        path: ":id",
        element: (
          <Suspense>
            <SessionEdit />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "settings",
    children: [
      {
        index: true,
        element: <Navigate to="staff" />,
      },
      {
        path: "staff",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <CouncilStaffPage />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense>
                <CouncilStaffAdd />
              </Suspense>
            ),
          },
          {
            path: "actions-history",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ActionsListPage />
                  </Suspense>
                ),
              },
              {
                path: ":id",
                element: (
                  <Suspense>
                    <ActionShowPage />
                  </Suspense>
                ),
              },
            ],
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <StaffPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "standing-commissions",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <StandingCommissions linkKey="STANDING_COMMISSIONS" />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense>
                <StandingCommissionsAddPage linkKey="STANDING_COMMISSIONS" />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <StandingCommissionsEditPage linkKey="STANDING_COMMISSIONS" />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "temporary-commissions",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <StandingCommissions linkKey="TEMPORARY_COMMISSIONS" />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense>
                <StandingCommissionsAddPage linkKey="TEMPORARY_COMMISSIONS" />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <StandingCommissionsEditPage linkKey="TEMPORARY_COMMISSIONS" />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "deputy-groups",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <StandingCommissions linkKey="DEPUTY_GROUPS" />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense>
                <StandingCommissionsAddPage linkKey="DEPUTY_GROUPS" />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <StandingCommissionsEditPage linkKey="DEPUTY_GROUPS" />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "positions",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <PositionsPage />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense>
                <CreatePositionPage />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <CreatePositionPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "counties",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <CountiesPage />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense>
                <CreateCountyPage />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <CreateCountyPage />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "contacts",
        element: (
          <Suspense>
            <ContactInfoPage />
          </Suspense>
        ),
      },
      {
        path: "deputy-count",
        element: (
          <Suspense>
            <DeputyCountPage />
          </Suspense>
        ),
      },
      {
        path: "deputy-table",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <DeputyTableListPage />
              </Suspense>
            ),
          },
          {
            path: "add",
            element: (
              <Suspense>
                <DeputyTableAdd />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <DeputyTableEdit />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "instructions",
    element: (
      <Suspense>
        <InstructionsPage />
      </Suspense>
    ),
  },
];
