import React, { FC } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
} from "@mui/material";
import { SelectOptions } from "../../interfaces/utility";

interface Props extends SelectProps<any> {
  options?: readonly SelectOptions[];
  helperText?: string | false;
  error?: boolean;
  labelIsBold?: boolean;
}

export const SelectUI: FC<Props> = (props) => {
  const {
    options,
    id,
    name,
    helperText,
    error,
    value,
    onChange,
    multiple,
    disabled,
    sx,
    MenuProps,
    endAdornment,
    labelIsBold,
  } = props;

  return (
    <FormControl
      fullWidth
      sx={{
        "& .MuiInputLabel-root": {
          fontWeight: labelIsBold === true ? "bold" : "default",
        },
      }}
    >
      {Boolean(props.label) && (
        <InputLabel shrink sx={{ background: "#fff" }} error={error} id={id}>
          {props.label}
        </InputLabel>
      )}
      <Select
        disabled={disabled}
        error={error}
        id={id}
        name={name}
        value={value ?? ""}
        labelId={id}
        onChange={onChange}
        multiple={multiple}
        sx={sx}
        MenuProps={MenuProps}
        endAdornment={endAdornment}
      >
        {props.children ??
          options?.map((item) => (
            <MenuItem
              key={item.value}
              value={item.value}
              disabled={item.disable}
            >
              {item.title}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText error={error}>
        {Boolean(error) && helperText}
      </FormHelperText>
    </FormControl>
  );
};
