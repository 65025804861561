import { httpDelete, httpGet, httpPatch, httpPost } from "../index";
import { IRespBody } from "../types/resp-body/resp-body";
import {
  CreateSessionData,
  SessionInfo,
  SessionListRes,
  NotificationsListRes,
  UpdateSession,
  NotesListRes,
} from "../types/dashboard/session";
import { ISource } from "../../interfaces/dashboard/document/source/source";

const session = {
  index: async (params: { size?: number; page?: number }) =>
    await httpGet<IRespBody<SessionListRes>>({
      url: "/dashboard/sessions/index",
      params,
    }),
  note: async (params: { lang: string }) =>
    await httpGet<IRespBody<NotesListRes[]>>({
      url: "/dashboard/sessions/notes",
      params,
    }),
  noteList: async (params: { lang: string }) =>
    await httpGet<IRespBody<NotificationsListRes>>({
      url: "/dashboard/sessions/note-list",
      params,
    }),
  show: async (id: number) =>
    await httpGet<IRespBody<SessionInfo>>({
      url: `/dashboard/sessions/show/${id}`,
    }),
  store: async (data: CreateSessionData) =>
    await httpPost({ url: "/dashboard/sessions/store", data }),
  update: async (
    data:
      | UpdateSession
      | { id: number; is_visible: string; type_id: number; _method: string }
  ) => await httpPatch({ url: "/dashboard/sessions/update", data }),
  sessionTypes: async () =>
    await httpGet<IRespBody<ISource[]>>({ url: "dashboard/sessions/types" }),
  delete: async (id: number) =>
    await httpDelete({ url: `/dashboard/sessions/delete/${id}` }),
};

export default session;
