import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { actionTypes } from "../constants";
import dayjs from "dayjs";
// import file from "../services/dashboard/file";

export const useScrollToTop = (): void => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export const cutString = (str: string): string => {
  const firstSpaceIndex = str.indexOf(" ");
  if (firstSpaceIndex !== -1) {
    return str.slice(0, firstSpaceIndex);
  } else {
    return str; // Return the original string if no space is found
  }
};

export const deepClone = <T = any>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const calculateTotalAmount = (
  mappedArray: any[],
  keyName: string
): number => {
  // Check if input is an array
  if (!Array.isArray(mappedArray)) {
    throw new Error("Input must be an array of numbers");
  }

  // Reduce the array to a single value (the total)
  const totalAmount = mappedArray.reduce((accumulator, currentValue) => {
    return Number(accumulator) + Number(currentValue[keyName]);
  }, 0);
  return totalAmount;
};

export const buildUrl = (
  header: string,
  query: URLSearchParams,
  parentId: string
): string => {
  const initialParams = {
    is_province:
      Boolean(query.get("district")) &&
      Boolean(query.get("region")) &&
      query.get("district") === query.get("region"),
    district_id:
      query.get("district") != null &&
      query.get("district") !== "" &&
      query.get("district") !== query.get("region")
        ? Number(query.get("district"))
        : undefined,
    region_id:
      query.get("region") != null && query.get("region") !== ""
        ? Number(query.get("region"))
        : undefined,
    year:
      query.get("year") != null && query.get("year") !== ""
        ? query.get("year") ?? ""
        : dayjs().year().toString(),
    month:
      query.get("month") != null && query.get("month") !== ""
        ? query.get("month") ?? ""
        : "0" + (dayjs().month() + 1).toString(),
    quarter:
      query.get("quarter") != null && query.get("quarter") !== ""
        ? query.get("quarter") ?? ""
        : undefined,
    start_date:
      query.get("start_date") != null && query.get("start_date") !== ""
        ? query.get("start_date") ?? ""
        : undefined,
    end_date:
      query.get("end_date") != null && query.get("end_date") !== ""
        ? query.get("end_date") ?? ""
        : undefined,
  };

  const currentParams = actionTypes[parentId]?.find(
    (item: any) => item.action === header
  );
  const allParams: any = { ...initialParams, ...currentParams };
  let result: string = "?";
  for (const key in allParams) {
    if (allParams[key] != null) {
      result = result + key + "=" + encodeURIComponent(allParams[key]) + "&";
    }
  }
  result = result.slice(0, -1);

  return result;
};

export const parseParams = (url: string): object => {
  const urlSearchParams = new URLSearchParams(url.split("?")[1]);
  const params = Object.fromEntries(urlSearchParams);
  return params;
};

// export const checkFileSaveStatus = (
//   fileId: number,
//   intervalId: number
// ): boolean => {
//   file
//     .checklistStatus({ file_id: fileId })
//     .then((res) => {
//       console.log(res); // Log the file status
//       const docStatus: boolean = res?.data?.data?.docx_generated;
//       if (res.status === 200 && docStatus) {
//         clearInterval(intervalId);
//         return true; // Stop polling when file is saved
//       }
//     })
//     .catch((error) => {
//       console.error("Error fetching file status:", error);
//     });
//   return false;
// };
