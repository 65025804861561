import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    mode: "light",
    background: {
      default: "#f2f4f4",
    },
    // text: {
    // primary: "#838383",
    // },
  },
  typography: {
    body1: {
      fontWeight: 500,
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          // backgroundColor: '#6A8D92'
          backgroundColor: "#ffffff",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: "40px",
          fieldset: {
            // height: "40px",
            top: 0,
            legend: {
              display: "none",
            },
          },
        },
        multiline: {
          height: "initial",
        },
        input: {
          padding: "8.5px 14px",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // transform: 'translate(14px, 9px) scale(1)',
          transform: "initial",
          position: "static",
          marginBottom: "8px",
          fontSize: "1.1em",
          color: "#242424",
        },
        shrink: {
          // transform: 'translate(14px, -9px) scale(0.75)',
          transform: "initial",
          position: "static",
          marginBottom: "8px",
          fontSize: "1.1em",
          color: "#242424",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: "45px",
          borderRadius: "25px",
          fontSize: "20px",
          textTransform: "initial",
        },
        containedPrimary: {
          backgroundColor: "#282B90",
          "&:hover": {
            backgroundColor: "#1f216a",
          },
        },
        outlined: {
          borderColor: "#282B90",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderRadius: "10px",
          overflow: "hidden",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(249, 249, 249, 1)",
          "&:nth-of-type(even)": {
            backgroundColor: "rgba(255, 255, 255, 1)",
          },
        },
        head: {
          backgroundColor: "#282B90",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderRight: "1px solid rgba(220, 220, 220, 1)",
          color: "#000000",
          "&:last-child": {
            borderRight: "none",
          },
          // "&:first-child": {
          //   fontSize: "1.1em",
          //   fontWeight: "bold",
          // },
        },
        head: {
          color: "#ffffff",
          fontSize: "1.2em",
          fontWeight: "bold",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root .MuiAutocomplete-input": {
            padding: "0",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          maxWidth: "320px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "normal",
        },
      },
    },
  },
});
