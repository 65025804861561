import React from "react";
import { Box } from "@mui/material";
import bg from "../../assets/media/not-found.svg";

function NoMatch(): JSX.Element {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        background: `url(${bg}) center center no-repeat`,
        backgroundSize: "cover",
      }}
    >
      {/* <BgSvg/> */}
      <Box
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          404
        </Box>
        {/* <span><b>404</b> | This page could not be found.</span> */}
      </Box>
    </Box>
  );
}

export default NoMatch;
