import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ru from "./locale/ru/common.json";
import uz from "./locale/uz/common.json";
import oz from "./locale/oz/common.json";
import qr from "./locale/qr/common.json";
import kr from "./locale/kr/common.json";

void i18n.use(initReactI18next).init({
  resources: {
    ru: { translation: ru },
    uz: { translation: uz },
    oz: { translation: oz },
    qr: { translation: qr },
    kr: { translation: kr },
  },
  lng: "ru",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
    formatSeparator: ",",
  },
  react: {
    useSuspense: true,
  },
});
export default i18n;
