import { createStore } from "effector";
import { tosterClose, tosterOpen } from "./events";
import { TosterType } from "../../interfaces/utility";
import { fetchDevModeFx } from "./effects";

interface TosterData extends TosterType {
  open: boolean;
}

export const $tosterData = createStore<TosterData>({
  open: false,
  message: "",
  severity: "success",
})
  .on(tosterOpen, (state, res) => {
    return {
      ...state,
      ...res,
      open: true,
    };
  })
  .reset(tosterClose);

export const $devMode = createStore<boolean>(false).on(
  fetchDevModeFx.doneData,
  (state, res) => res.data.data.status
);
