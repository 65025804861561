import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  SelectChangeEvent,
  // Menu,
  // Popover,
  // MenuItem,
  // Paper,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import LogoPng from "../../../../assets/media/svg-files/logo-gray.svg";
import { SelectUI } from "../../../../components/select";
import Cookies from "js-cookie";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import bgHeader from "../../../../assets/media/svg-files/header-bg.svg";
import { Container } from "../../../../components/container";
import { TextAlignLeft, UserSvg } from "../../../../assets/media/svg-icons";
import { Close } from "@mui/icons-material";
import { ManuWrapper } from "../atoms";
import { MenuBg } from "../../../../assets/media/menu-bg";
import SideMenuHome from "../../../../components/side-menu-home";
import SideMenuLegislation from "../../../../components/side-menu-category";
// import SideMenuHome from "../../../../components/side-menu-home";

export const HomePageHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const [lang, setLang] = useState(Cookies.get("lang") ?? "oz");
  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const changeLanguage = (e: SelectChangeEvent<unknown>): void => {
    const lang = e.target.value as string;
    Cookies.set("lang", lang);
    setLang(lang);
    void i18n.changeLanguage(lang);
  };

  const toggleMenu = (): void => {
    setMenuVisible(!menuVisible);
  };

  const [isOpen, setIsOpen] = useState({ main: false, second: false });

  const handleMouseEnter = (name: string): void => {
    if (name === "main") setIsOpen({ ...isOpen, main: true });
    if (name === "second") setIsOpen({ ...isOpen, second: true });
  };

  const handleMouseLeave = (name: string): void => {
    if (name === "main") setIsOpen({ ...isOpen, main: false });
    if (name === "second") setIsOpen({ ...isOpen, second: false });
  };

  useEffect(() => {
    if (menuVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [menuVisible]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "174px",
        position: "fixed",
        background: "#fff",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 9,
        "&:before": {
          content: "''",
          display: "flex",
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: `url(${bgHeader}) no-repeat center top`,
          zIndex: "1",
          opacity: "0.3",
        },
      }}
    >
      <ManuWrapper className={menuVisible ? "open" : "close"}>
        <div className="bg">
          <MenuBg />
        </div>
        <div className="content">
          <IconButton
            sx={{
              height: "40px",
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "6px",
              border: "1px solid rgba(197, 199, 200, 0.7)",
              marginRight: "40px",
              transitions: ".3s",
              "&:hover": {
                backgroundColor: "#2455A2",
                color: "#fff",
              },
            }}
            onClick={toggleMenu}
          >
            <Close />
          </IconButton>
          <Box
            className="links"
            sx={{
              "& a.active": {
                color: "#FFF !important",
              },
            }}
          >
            <NavLink onClick={toggleMenu} to="/#council">
              {t("council_info")}
            </NavLink>
            <NavLink onClick={toggleMenu} to="/deputy-know">
              {t("deputy_know")}
            </NavLink>
            <NavLink onClick={toggleMenu} to="/#senat-committee">
              {t("senat_committee")}
            </NavLink>
            <NavLink onClick={toggleMenu} to="/news">
              {t("news")}
            </NavLink>
            <NavLink onClick={toggleMenu} to="/doc">
              {t("decisions")}
            </NavLink>
          </Box>
        </div>
      </ManuWrapper>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          zIndex: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              img: {
                width: "50px",
              },
              a: {
                display: "inline-flex",
                alignItems: "center",
                textDecoration: "none",
              },
              ".title": {
                width: "300px",
                marginLeft: "30px",
                textTransform: "uppercase",
                fontSize: "17px",
                color: "#454C5D",
                fontWeight: 600,
                b: { fontWeight: 800 },
              },
            }}
          >
            <Link to="/">
              <img src={LogoPng} alt="logo" />
              <div
                className="title"
                dangerouslySetInnerHTML={{ __html: t("header_title") }}
              />
            </Link>
          </Box>

          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                ".MuiInputBase-root": {
                  height: "50px",
                  borderRadius: "6px",
                  border: "1px solid #C5C7C8",
                  color: "#454C5D",
                  backgroundColor: "#fff",
                },
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff !important",
                  borderWidth: "1px",
                },
                ".MuiSvgIcon-root": {
                  color: "#454C5D",
                },
              }}
            >
              <SelectUI
                value={lang}
                options={[
                  {
                    title: "O'Z",
                    value: "oz",
                  },
                  {
                    title: "ЎЗ",
                    value: "uz",
                  },
                  {
                    title: "QR",
                    value: "qr",
                  },
                  {
                    title: "КР",
                    value: "kr",
                  },
                  {
                    title: "РУ",
                    value: "ru",
                  },
                ]}
                onChange={changeLanguage}
              />
            </Box>
            <Box
              sx={{
                marginLeft: "16px",
                a: {
                  height: "50px",
                  backgroundColor: "#2455A2",
                  borderRadius: "6px",
                  color: "#ffffff",
                  fontSize: "18px",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0 16px",
                  whiteSpace: "nowrap",
                  svg: {
                    marginRight: "8px",
                  },
                },
              }}
            >
              <Link to="/dashboard">
                <UserSvg />
                {t("cabinet")}
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "5px 0",
            a: {
              textTransform: "uppercase",
              padding: "5px 10px",
              fontSize: "18px",
              textDecoration: "none",
              color: "#050F27",
              fontWeight: "600",
              transition: ".3s",
              "&:hover, &.active": {
                color: "#0f50b4",
              },
            },
          }}
        >
          <Box
            onMouseEnter={() => {
              handleMouseEnter("main");
            }}
            onMouseLeave={() => {
              handleMouseLeave("main");
            }}
          >
            <IconButton
              sx={{
                height: "40px",
                width: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "6px",
                border: "1px solid #C5C7C8",
                backgroundColor: "#fff",
                marginRight: "16px",
                color: "#454C5D",
                transitions: ".3s",
                "&:hover": {
                  backgroundColor: "#2455A2",
                  color: "#fff",
                },
              }}
            >
              <TextAlignLeft />
            </IconButton>

            {isOpen.main && <SideMenuHome />}
          </Box>
          <NavLink
            to="/about-council"
            // style={{ opacity: "0.5", pointerEvents: "none", color: "#050F27" }}
          >
            {t("council_info")}
          </NavLink>
          <NavLink to="/deputy-know">{t("deputy_know")}</NavLink>
          <NavLink
            to="/senat-committee"
            // style={{ opacity: "0.5", pointerEvents: "none", color: "#050F27" }}
          >
            {t("senat_committee")}
          </NavLink>
          <NavLink to="/news">{t("news")}</NavLink>
          <NavLink to="/doc">{t("decisions")}</NavLink>
          <Box
            onMouseEnter={() => {
              handleMouseEnter("second");
            }}
            onMouseLeave={() => {
              handleMouseLeave("second");
            }}
          >
            <NavLink to="/legislation">{t("legislation")}</NavLink>
            {isOpen.second && <SideMenuLegislation />}
          </Box>
          {/* <Link to="/senators">{t("senators")}</Link> */}
          <NavLink
            to="/registry"
            // style={{ opacity: "0.5", pointerEvents: "none", color: "#050F27" }}
          >
            {t("registry")}
          </NavLink>
        </Box>
      </Container>
    </Box>
  );
};
