import { styled } from "@mui/material";

export const DeputyKnowContainer = styled("div")`
  padding: 32px 0;
  background-color: #ebedef;

  & > h2 {
    text-transform: uppercase;
    font-size: 24px;
    color: #242424;
    text-align: center;
    margin-bottom: 70px;
    line-height: 1;
  }
`;

export const DeputyKnowWrapper = styled("div")`
  box-shadow: 0 15px 20px #151d410f;
  border-radius: 10px;
  padding: 50px 40px;
  background: #f5f6f7;
  min-height: 1000px;
  position: relative;
`;

export const FiltersBlock = styled("div")`
  display: flex;
  margin-bottom: 30px;
  margin-top: 30px;

  .MuiInputBase-root {
    background: #fff;
    border-radius: 12px;
  }

  fieldset {
    border: none;
  }

  div.MuiAutocomplete-root {
    width: 100%;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }

  label {
    background: inherit;
    color: #091f7b;
  }
`;

export const SearchBlock = styled("div")`
  display: flex;
  margin-bottom: 30px;
  background: #fff;
  height: 80px;
  border-radius: 14px;
  position: absolute;
  width: 100%;
  top: -40px;
  max-width: 1324px;
  left: 50%;
  transform: translateX(-50%);

  fieldset {
    border: none;
    height: 80px;
  }
  .MuiInputBase-root {
    height: 100%;
  }
  .MuiIconButton-root {
    color: #fff;
    border-radius: 14px;
    background-color: #2455a2;
    border: 1px solid #e2e4e8;
  }
`;
export const DeputyGroup = styled("div")`
  display: block;
  padding: 30px 0;
  h3 {
    color: #696974;
    margin-bottom: 15px;
    font-weight: 400;
  }
`;
export const DeputyCardWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;
export const DeputyCard = styled("div")`
  display: flex;
  align-items: center;
  border: 1px solid #e2e4e8;
  border-radius: 14px;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #fff;

  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 13px;
    margin-right: 18px;
  }
  > div > a {
    color: #171725;
    font-weight: 700;
    font-size: 20px;
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;
export const CountryItem = styled("div")`
  display: flex;
  color: #171725;
  align-items: center;
  margin: 12px 0 10px;
  svg {
    margin-right: 7px;
  }
`;
export const PhoneItem = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  svg {
    margin-right: 7px;
  }
`;

export const QuarterItem = styled("div")`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  svg {
    margin-right: 7px;
  }
`;
