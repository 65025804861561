import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { HomePageHeader } from "./molecules";
import { Footer } from "../../../components/footer";
import i18n from "i18next";
import Cookies from "js-cookie";

export const HomeLayout = (): JSX.Element => {
  useEffect(() => {
    void i18n.changeLanguage(Cookies.get("lang") ?? "oz");
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        backgroundSize: "100% 1080px",
        flexDirection: "column",
        position: "relative",
        background: "#F5F6F7",
        paddingTop: "174px",
      }}
    >
      <HomePageHeader />
      <Box
        sx={{
          position: "relative",
          zIndex: 3,
          minHeight: "80vh",
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};
