import React, { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { UserAvatar } from "../../assets/media/svg-icons";
import CloseIcon from "@mui/icons-material/Close";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import InputMask from "react-input-mask";
import { ReactI18NextChild, useTranslation } from "react-i18next";
import { $account, changeRoleEvent, getUserInfo } from "../../model/account";
import { useStore } from "effector-react";
import user from "../../services/dashboard/user";
import { tosterOpen } from "../../model/app";
import Cookies from "js-cookie";

const ProfilePopup = (): JSX.Element => {
  const lang = Cookies.get("lang") ?? "oz";
  const userData = useStore($account).$accountData.data;
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [editPhoneVisible, setEditPhoneVisible] = useState(false);
  const [phoneNumberData, setPhoneNumberData] = useState({
    actual_phone: userData?.actual_phone_number ?? "",
    visible: userData?.is_phone_visible ?? false,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPhoneNumberData({
      ...phoneNumberData,
      visible: event.target.value === "true",
    });
  };

  const handleClickOpen = (): void => {
    setOpenDialog(true);
  };

  const handleCloseDialog = (): void => {
    setOpenDialog(false);
  };

  const handleSaveNumber = (): void => {
    const param = {
      user_id: Number(userData?.id),
      ...phoneNumberData,
    };
    void user.addDeputyPhone(param).then((res) => {
      if (res.status === 200) {
        getUserInfo(lang);
        setEditPhoneVisible(false);
        tosterOpen({ message: "success", severity: "success" });
      }
    });
  };

  const handleChangeNumber = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPhoneNumberData({
      ...phoneNumberData,
      actual_phone: event.target.value,
    });
  };

  const handleChangeEditPhoneBtn = (): void => {
    setEditPhoneVisible(!editPhoneVisible);
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        sx={{
          display: "flex",
          color: "#fff",
          textAlign: "start",
          borderRadius: 0,
          margin: "0 0.3em 0.3em 1.25em",
          minHeight: "90px",
          height: "100%",
          "& > svg": {
            height: "40px",
            width: "24px",
          },
        }}
      >
        <UserAvatar />
        <Box
          sx={{
            marginLeft: "15px",
            maxWidth: "250px",
          }}
        >
          <Typography fontSize="16px">{userData?.ful_name ?? ""}</Typography>
          <Typography fontSize="14px">
            {userData?.roles?.find((role) => role.id === userData?.role_id)
              ?.title ?? ""}
          </Typography>
          <Box
            sx={{
              marginRight: "5px",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
              "& > span": {
                marginRight: "5px",
              },
            }}
          >
            <span>{t("shot_phone")}:</span>
            <span>{userData?.actual_phone_number ?? "-"}</span>
          </Box>
        </Box>
      </Button>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        fullWidth={true}
        maxWidth="md"
        scroll="paper"
      >
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            maxHeight: "600px",
            padding: "30px 50px",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            position: "relative",
            img: {
              height: "100%",
              width: "100%",
            },
          }}
        >
          <Box
            sx={{
              height: "350px",
              width: "350px",
              margin: "20px",
            }}
          >
            {userData?.image != null ? (
              <img src={userData?.image} alt="img" />
            ) : (
              <AccountBoxIcon
                sx={{
                  height: "350px",
                  width: "350px",
                }}
              />
            )}
          </Box>
          <Box
            id="dialog-description"
            sx={{
              flex: "1",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                fontWeight: "700",
                color: "#4d4b4b",
              }}
            >
              {userData?.ful_name}
            </Typography>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "700",
                marginBottom: "10px",
                color: "#300395",
              }}
            >
              {userData?.authority_title ?? ""}
            </Typography>
            <Box sx={{ display: "flex", marginBottom: "5px" }}>
              <Typography marginRight="5px" fontSize="16px">
                {t("user")}:
              </Typography>
              <Typography>{userData?.user_id}</Typography>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "5px" }}>
              <Typography marginRight="5px" fontSize="16px">
                {t("pin")}:
              </Typography>
              <Typography>{userData?.pin}</Typography>
            </Box>
            <Box sx={{ display: "flex", marginBottom: "5px" }}>
              <Typography marginRight="5px" fontSize="16px">
                {t("region")}:
              </Typography>
              <Typography>{userData?.region_title}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                marginBottom: "15px",
                alignItems: editPhoneVisible ? "start" : "center",
              }}
            >
              {editPhoneVisible ? (
                <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <div>
                    <InputMask
                      value={phoneNumberData.actual_phone}
                      onChange={handleChangeNumber}
                      mask="+999999999999"
                      maskChar=""
                      beforeMaskedValueChange={(newState: any) => {
                        return {
                          ...newState,
                          value: newState.value.toUpperCase(),
                        };
                      }}
                    >
                      {
                        // @ts-expect-error
                        () => {
                          return (
                            <TextField
                              fullWidth
                              id="actual_phone"
                              name="actual_phone"
                              variant="outlined"
                            />
                          ) as ReactI18NextChild;
                        }
                      }
                    </InputMask>
                    <FormControl
                      component="fieldset"
                      sx={{
                        "& > div": {
                          flexDirection: "row",
                        },
                      }}
                    >
                      <RadioGroup
                        aria-label="gender"
                        name="gender"
                        value={phoneNumberData.visible}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label={t("hidden")}
                        />
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label={t("not_hidden")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {/* <TextField */}
                  {/*  value={phoneNumberData.actual_phone} */}
                  {/*  fullWidth */}
                  {/*  id="actual_phone" */}
                  {/*  name="actual_phone" */}
                  {/*  placeholder={t("search_deputy_by_fio")} */}
                  {/*  variant="outlined" */}
                  {/*  onChange={handleChangeNumber} */}
                  {/* /> */}
                  <Button
                    disabled={phoneNumberData.actual_phone.length !== 13}
                    sx={{
                      marginLeft: "10px",
                      fontSize: "16px",
                      padding: "0 22px",
                      height: "40px",
                      borderRadius: "6px",
                    }}
                    variant="contained"
                    onClick={handleSaveNumber}
                  >
                    {t("save")}
                  </Button>
                </Box>
              ) : (
                <>
                  <Typography marginRight="5px" fontSize="16px">
                    {t("phone")}:
                  </Typography>
                  <Typography>
                    {userData?.actual_phone_number ?? "-"}
                  </Typography>
                </>
              )}
              <Button
                variant="contained"
                sx={{
                  marginLeft: "10px",
                  fontSize: "16px",
                  padding: "0 10px",
                  height: editPhoneVisible ? "40px" : "30px",
                  borderRadius: "6px",
                  background: editPhoneVisible ? "red" : "#300395",
                }}
                onClick={handleChangeEditPhoneBtn}
              >
                {editPhoneVisible ? t("close") : t("add_or_edit")}
              </Button>
            </Box>
            <Stack
              direction="row"
              spacing={1}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {userData?.roles?.map((item) => {
                return (
                  <Chip
                    key={item.id}
                    color="info"
                    label={item.title}
                    className={userData.role_id === item.id ? "active" : ""}
                    onClick={() => changeRoleEvent(item.id)}
                    sx={{
                      marginBottom: "5px !important",
                      cursor: "pointer",
                      "&:hover, &.active": { background: "#300395" },
                    }}
                  />
                );
              })}
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ProfilePopup;
