import { useTranslation } from "react-i18next";
import {
  Link,
  useLocation,
  useNavigate,
  // useSearchParams,
} from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  Box,
  Breadcrumbs,
  IconButton,
  // Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import {
  IAppointmentData,
  IAppointment,
} from "../../../../interfaces/dashboard/appointment";
import appointments from "../../../../services/dashboard/appointment";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "js-cookie";
// import { Add } from "@mui/icons-material";
// import { DownloadTableExcel } from "react-export-table-to-excel";
// import { DownloadExelSvg } from "../../../../assets/media/svg-icons";
// import { SelectUI } from "../../../../components/select";

function InBoxAppointment(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const tableRef = useRef(null);
  const locationsList = location.pathname.split("/");
  const currentLocation = locationsList[locationsList.length - 1] ?? undefined;
  const lang = Cookies.get("lang") ?? "oz";
  // const docs = useStore($docs);
  // const inBoxAllDocs: IStore<IncomingListResp> = docs.$inBoxDocs;
  const [appointmentData, setAppointmentData] =
    useState<IAppointmentData | null>(null);
  const [page, setPage] = useState<number>(0);
  const [limit, setLimit] = useState<number>(10);
  //   const [query, setQuery] = useSearchParams();
  //   const [queryParam, setParams] = useState({
  //     reg_number: query.get("reg_number") ?? "",
  //     description: query.get("description") ?? "",
  //     document_type_id: query.get("document_type_id") ?? "",
  //     sending_authority_name: query.get("sending_authority_name") ?? "",
  //   });

  // const handleChangeParam = ({ target: { name, value } }: any): void => {
  //   setParams({ ...queryParam, [name]: value });
  // };

  // useEffect(() => {
  //   setQuery(queryParam);
  // }, [queryParam]);

  // const addDoc = (): void => {
  //   navigate("/dashboard/new-doc");
  // };

  const handleChangePage = (event: unknown, newPage: number): void => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setLimit(+event.target.value);
    setPage(0);
  };

  // const handleShowPassport = (currentLoc: string, id: number): void => {
  //   navigate(`/dashboard/in-doc/${currentLoc}/${id}`);
  // };

  // const allDocsStatusId = inBoxAllDocs.data?.items.map((item) => {
  //   return idss.find((items) => items.val === item.document_status.id);
  // });
  //
  // const currentDocStatusId = allDocsStatusId?.map((item) => item?.title);

  // const currentLinkDoc = idss.find((item) => item.val === allDocsStatusId).title ?? "";

  useEffect(() => {
    const params = {
      size: limit,
      page: page + 1,
      lang,
    };
    void appointments.index(params).then((res) => {
      setAppointmentData(res?.data.data);
    });
  }, [limit, lang, page]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          marginBottom: "1.25em",
          justifyContent: "space-between",
          h1: {
            margin: 0,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <h1>{t("all_docs")} | </h1>
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              marginLeft: "1em",
              display: "flex",
              alignItems: "center",
              ".MuiBreadcrumbs-li": {
                display: "inline-flex",
                alignItems: "center",
              },
              a: {
                display: "flex",
                alignItems: "center",
                color: "inherit",
                fontSize: "inherit",
              },
            }}
          >
            <Link color="inherit" to="/">
              <HomeIcon sx={{ mr: 0.5, fontSize: "2em" }} fontSize="inherit" />
            </Link>
            <Link color="inherit" to="/dashboard/in-doc">
              {t("incoming_documents")}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {currentLocation !== "" && t(currentLocation.replace("-", "_"))}
            </Typography>
          </Breadcrumbs>
        </Box>
        {/* <div> */}
        {/*  <Button */}
        {/*    color="success" */}
        {/*    variant="contained" */}
        {/*    startIcon={<Add />} */}
        {/*    onClick={addDoc} */}
        {/*    sx={{ fontSize: "16px", marginRight: "20px" }} */}
        {/*  > */}
        {/*    {t("create_new_document")} */}
        {/*  </Button> */}
        {/*  <DownloadTableExcel */}
        {/*    filename="E-kengash list" */}
        {/*    sheet="users" */}
        {/*    currentTableRef={tableRef.current} */}
        {/*  > */}
        {/*    <Button */}
        {/*      variant="contained" */}
        {/*      color="info" */}
        {/*      sx={{ */}
        {/*        fontSize: "16px", */}
        {/*      }} */}
        {/*      // sx={{ */}
        {/*      //     background: '#5EBDD9', */}
        {/*      //     hover: { */}
        {/*      //         background: '#5EBDD9 !important', */}
        {/*      //         opacity: '0.5' */}
        {/*      //     } */}
        {/*      // }} */}
        {/*      startIcon={<DownloadExelSvg />} */}
        {/*    > */}
        {/*      {t("download_xls")} */}
        {/*    </Button> */}
        {/*  </DownloadTableExcel> */}
        {/* </div> */}
      </Box>
      <Box
        sx={{
          width: "100%",
          minHeight: "100%",
          borderRadius: "0.6em",
          boxShadow: "0 0.3em 0.5em #BABABAFF",
          padding: "1.25em 1.9em",
          background: "#fff",
        }}
      >
        <Table sx={{ minWidth: 650 }} aria-label="simple table" ref={tableRef}>
          <TableHead
            sx={{
              th: {
                fontWeight: "bold",
              },
              minWidth: 650,
              borderRadius: "0 10px 10px",
              overflow: "hidden",
            }}
          >
            <TableRow>
              {/* <TableCell>{t("id")}</TableCell> */}

              <TableCell align="left">
                <span>{t("preview")}</span>
                <TextField
                  fullWidth
                  sx={{ background: "#fff", borderRadius: "6px" }}
                  // value={query.get("description")}
                  id="description"
                  name="description"
                  variant="outlined"
                  // onChange={handleChangeParam}
                />
              </TableCell>
              <TableCell align="left">
                <span>{t("deputies")}</span>
                <TextField
                  fullWidth
                  sx={{ background: "#fff", borderRadius: "6px" }}
                  // value={query.get("reg_number")}
                  id="reg_number"
                  name="reg_number"
                  variant="outlined"
                  // onChange={handleChangeParam}
                />
              </TableCell>
              <TableCell align="left">
                <span>{t("appointment_time")}</span>
              </TableCell>
              <TableCell align="left">{t("document_status")}</TableCell>
              {/* <TableCell align="left"> */}
              {/*  <span>{t("authority_name")}</span> */}
              {/*  <TextField */}
              {/*    fullWidth */}
              {/*    sx={{ background: "#fff", borderRadius: "6px" }} */}
              {/*    value={query.get("sending_authority_name")} */}
              {/*    id="sending_authority_name" */}
              {/*    name="sending_authority_name" */}
              {/*    variant="outlined" */}
              {/*    onChange={handleChangeParam} */}
              {/*  /> */}
              {/* </TableCell> */}
              {/* <TableCell align="center">{t("actions")}</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {appointmentData?.items?.map((item: IAppointment) =>
              item.latest_history == null ? (
                <TableRow
                  key={item.id}
                  sx={{
                    a: {
                      color: "inherit",
                    },
                  }}
                >
                  {/* <TableCell component="th" scope="row"> */}
                  {/*   {item.id} */}
                  {/* </TableCell> */}

                  <TableCell align="left">
                    <Link to={`${(item?.id as number) ?? "#"}`}>
                      {item.description}
                    </Link>
                  </TableCell>
                  <TableCell align="left">{item?.deputy?.name}</TableCell>
                  <TableCell align="left">
                    {item?.date ?? ""} {item?.time ?? ""}
                  </TableCell>
                  <TableCell align="left">
                    {/* {item?.status?.title ?? ""} */}
                    <IconButton>
                      <EditIcon
                        onClick={() => {
                          if (item?.id != null) navigate(`${item?.id}/edit`);
                        }}
                      />
                    </IconButton>
                    <IconButton>
                      <VisibilityIcon
                        onClick={() => {
                          if (item?.id != null) navigate(`${item?.id}`);
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow
                  key={item?.latest_history?.id}
                  sx={{
                    a: {
                      color: "inherit",
                    },
                  }}
                >
                  <TableCell align="left">
                    <Link to={`${(item?.latest_history?.id as number) ?? "#"}`}>
                      {item?.latest_history?.description}
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    {item?.latest_history?.deputy?.name}
                  </TableCell>
                  <TableCell align="left">
                    {item?.latest_history?.date ?? ""}
                    {item?.latest_history?.time ?? ""}
                  </TableCell>
                  <TableCell align="left">
                    <IconButton>
                      <EditIcon
                        onClick={() => {
                          if (item?.latest_history?.id != null)
                            navigate(`${item?.latest_history?.id ?? ""}/edit`);
                        }}
                      />
                    </IconButton>
                    <IconButton>
                      <VisibilityIcon
                        onClick={() => {
                          if (item?.latest_history?.id != null)
                            navigate(`${item?.latest_history?.id}`);
                        }}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={appointmentData?.total ?? 0}
            rowsPerPage={limit}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={t("rows_per_page")}
            labelDisplayedRows={(paginationInfo) =>
              t("table_pagination_label", paginationInfo)
            }
          />
        </Box>
        {/* { */}
        {/*    outBoxAllDocs.data && outBoxAllDocs.data.total && */}
        {/*    <Pagination onChange={(_, v) => setPage(v)} count={Math.ceil((outBoxAllDocs.data.total || 0) / limit)} variant="outlined" shape="rounded"/> */}
        {/* } */}
      </Box>
    </Box>
  );
}

export default InBoxAppointment;
