import React, { lazy, Suspense } from "react";
import { Navigate, RouteObject } from "react-router-dom";

const NewDocPage = lazy(
  async () => await import("../pages/dashboard/docs-page/new-doc")
);
const CreateOutBoxLetterDoc = lazy(
  async () => await import("../pages/dashboard/create-new-doc/out-box/letter")
);
const CreateOutBoxDeputyRequestDoc = lazy(
  async () =>
    await import("../pages/dashboard/create-new-doc/out-box/deputy-request")
);
const OutBoxDocs = lazy(
  async () => await import("../pages/dashboard/out-box/list")
);
const ShowDoc = lazy(
  async () => await import("../pages/dashboard/out-box/show-doc")
);
const EditDoc = lazy(
  async () => await import("../pages/dashboard/out-box/edit-doc")
);
const EditOutBoxDoc = lazy(
  async () => await import("../pages/dashboard/out-box/edit")
);
const InstructionsPage = lazy(
  async () => await import("../pages/dashboard/instructions")
);
const InBoxDocs = lazy(
  async () => await import("../pages/dashboard/in-box/list")
);
const InboxShowDoc = lazy(
  async () => await import("../pages/dashboard/in-box/show-doc")
);
const InboxEditDoc = lazy(
  async () => await import("../pages/dashboard/in-box/edit")
);
const EditInDoc = lazy(
  async () => await import("../pages/dashboard/in-box/edit-doc")
);

export const SENATOR_ROUTE: RouteObject[] = [
  {
    path: "new-doc",
    children: [
      {
        index: true,
        element: (
          <Suspense>
            <NewDocPage />
          </Suspense>
        ),
      },
      {
        path: "transmittal-later",
        element: (
          <Suspense>
            <CreateOutBoxLetterDoc />
          </Suspense>
        ),
      },
      {
        path: "deputy-request",
        element: (
          <Suspense>
            <CreateOutBoxDeputyRequestDoc />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "in-doc",
    children: [
      {
        index: true,
        element: <Navigate to="for-signature" />,
      },
      {
        path: "for-signature",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InboxShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InboxEditDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditInDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "execution",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InboxShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <InboxEditDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditInDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "confirmation",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InboxShowDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "executed",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <InboxShowDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "all-docs",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <InBoxDocs />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "out-doc",
    children: [
      { index: true, element: <Navigate to="draft" /> },
      {
        path: "draft",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditOutBoxDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "for-signature",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditOutBoxDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "agreement",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <ShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "confirmed",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <ShowDoc />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "all-docs",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "rejected",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: (
                  <Suspense>
                    <ShowDoc />
                  </Suspense>
                ),
              },
              {
                path: "edit",
                element: (
                  <Suspense>
                    <EditOutBoxDoc />
                  </Suspense>
                ),
              },
              {
                path: ":docId",
                element: (
                  <Suspense>
                    <EditDoc />
                  </Suspense>
                ),
              },
            ],
          },
        ],
      },
      {
        path: "registration",
        children: [
          {
            index: true,
            element: (
              <Suspense>
                <OutBoxDocs />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense>
                <ShowDoc />
              </Suspense>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "instructions",
    element: (
      <Suspense>
        <InstructionsPage />
      </Suspense>
    ),
  },
];
