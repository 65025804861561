import {
  AddDocumentSvg,
  CheckDocumentSvg,
  InternalDocumentSvg,
  OutDocumentSvg,
  SettingsSvg,
} from "../assets/media/svg-icons";
import SummarizeIcon from "@mui/icons-material/Summarize";
import React from "react";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import GroupsIcon from "@mui/icons-material/Groups";
import { IEventGetData } from "../services/types/socket";
import { NavProps } from "../interfaces/utility";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NewspaperIcon from "@mui/icons-material/Newspaper";

export const ExpertCategories = (
  badgeNotice: IEventGetData | null
): NavProps[] => {
  return [
    {
      title: "home_page",
      link: "/dashboard",
      end: true,
    },
    {
      title: "add_new_document",
      link: "new-doc",
      icon: <AddDocumentSvg />,
    },
    {
      title: "incoming",
      link: "in-doc",
      count: badgeNotice?.incoming_count_all ?? undefined,
      icon: <CheckDocumentSvg />,
      children: [
        {
          title: "new",
          link: "in-doc/draft",
          count: badgeNotice?.incoming_count_new ?? undefined,
        },
        {
          title: "for_signature",
          link: "in-doc/for-signature",
          count: badgeNotice?.incoming_count_signing ?? undefined,
        },
        {
          title: "execution",
          link: "in-doc/execution",
          count: badgeNotice?.incoming_count_execution ?? undefined,
        },
        {
          title: "confirmation",
          link: "in-doc/confirmation",
          count: badgeNotice?.incoming_count_confirmation ?? undefined,
        },
        {
          title: "executed",
          link: "in-doc/executed",
          count: badgeNotice?.incoming_count_executed ?? undefined,
        },
        // {
        //   title: "appointment",
        //   link: "in-doc/appointment",
        //   // count: badgeNotice?.incoming_count_executed ?? undefined,
        // },
        {
          title: "all_docs",
          link: "in-doc/all-docs",
          count: badgeNotice?.incoming_count_all ?? undefined,
        },
      ],
    },
    {
      title: "outgoing_documents",
      link: "out-doc",
      count: badgeNotice?.outgoing_count_all ?? undefined,
      icon: <OutDocumentSvg />,
      children: [
        {
          title: "draft",
          link: "out-doc/draft",
          count: badgeNotice?.outgoing_count_draft ?? undefined,
        },
        {
          title: "for_signature",
          link: "out-doc/for-signature",
          count: badgeNotice?.outgoing_count_signing ?? undefined,
        },
        {
          title: "agreement",
          link: "out-doc/agreement",
          count: badgeNotice?.outgoing_count_agreement ?? undefined,
        },
        {
          title: "confirmed",
          link: "out-doc/confirmed",
          count: badgeNotice?.outgoing_count_approved ?? undefined,
        },
        {
          title: "rejected",
          link: "out-doc/rejected",
          count: badgeNotice?.outgoing_count_rejected ?? undefined,
        },
        {
          title: "retry_processing",
          link: "out-doc/retry-processing",
          count: badgeNotice?.outgoing_count_processing ?? undefined,
        },
        {
          title: "all_docs",
          link: "out-doc/all-docs",
          count: badgeNotice?.outgoing_count_all ?? undefined,
        },
      ],
    },
    {
      title: "internal_doc",
      link: "internal-doc",
      count: badgeNotice?.internal_count_all ?? undefined,
      icon: <InternalDocumentSvg />,
      children: [
        {
          title: "draft",
          link: "internal-doc/draft",
          count: badgeNotice?.internal_count_draft ?? undefined,
        },
        {
          title: "for_signature",
          link: "internal-doc/for-signature",
          count: badgeNotice?.internal_count_signing ?? undefined,
        },
        {
          title: "agreement",
          link: "internal-doc/agreement",
          count: badgeNotice?.internal_count_agreement ?? undefined,
        },
        {
          title: "confirmed",
          link: "internal-doc/confirmed",
          count: badgeNotice?.internal_count_approved ?? undefined,
        },
        {
          title: "rejected",
          link: "internal-doc/rejected",
          count: badgeNotice?.internal_count_reject ?? undefined,
        },
        {
          title: "rejected_from_council",
          link: "internal-doc/retry-processing",
          count: badgeNotice?.internal_count_returned_leader ?? undefined,
        },
        {
          title: "in_council",
          link: "internal-doc/in-council",
          // count: badgeNotice?.internal_count_in_council ?? undefined,
        },
        {
          title: "all_docs",
          link: "internal-doc/all-docs",
          count: badgeNotice?.internal_count_all ?? undefined,
        },
        {
          title: "basket",
          link: "internal-doc/basket",
          count: badgeNotice?.internal_count_basket ?? undefined,
        },
      ],
    },
    // {
    //   title: "reports",
    //   link: "report",
    //   count: badgeNotice?.report_count_all ?? undefined,
    //   icon: <SummarizeIcon />,
    //   children: [
    //     {
    //       title: "draft",
    //       link: "report/draft",
    //       count: badgeNotice?.report_count_draft ?? undefined,
    //     },
    //     {
    //       title: "for_signature",
    //       link: "report/for-signature",
    //       count: badgeNotice?.report_count_signing ?? undefined,
    //     },
    //     {
    //       title: "confirmed",
    //       link: "report/confirmed",
    //       count: badgeNotice?.report_count_approved ?? undefined,
    //     },
    //     {
    //       title: "all_docs",
    //       link: "report/all-docs",
    //       count: badgeNotice?.report_count_all ?? undefined,
    //     },
    //   ],
    // },
    {
      title: "reports",
      link: "reports",
      icon: <SummarizeIcon />,
    },
    {
      title: "notifications",
      link: "notifications",
      icon: <NotificationsActiveIcon />,
      count: badgeNotice?.note_count_all ?? undefined,
    },
    {
      title: "useful_info",
      link: "useful-info",
      icon: <NewspaperIcon />,
      children: [
        {
          title: "juridical_advice",
          link: "useful-info/juridical-advice",
        },
        {
          title: "useful",
          link: "useful-info/hyperlinks",
        },
        {
          title: "ministries_and_agencies",
          link: "useful-info/ministries-and-agencies",
        },
        // {
        //   title: "neighborhood",
        //   link: "useful-info/neighborhood",
        // },
      ],
    },
    {
      title: "news",
      link: "news",
      icon: <FiberNewIcon />,
    },
    {
      title: "sessions",
      link: "sessions",
      icon: <GroupsIcon />,
      count: badgeNotice?.session_count_all ?? undefined,
    },
    {
      title: "settings",
      link: "settings",
      icon: <SettingsSvg />,
      children: [
        {
          title: "staff_list",
          link: "settings/staff",
        },
        {
          title: "standing_commissions",
          link: "settings/standing-commissions",
        },
        {
          title: "temporary_commissions",
          link: "settings/temporary-commissions",
        },
        {
          title: "deputy_groups",
          link: "settings/deputy-groups",
        },
        {
          title: "positions",
          link: "settings/positions",
        },
        {
          title: "counties",
          link: "settings/counties",
        },
        {
          title: "deputy_table",
          link: "settings/deputy-table",
        },
        {
          title: "deputy_count",
          link: "settings/deputy-count",
        },
        {
          title: "contact_information",
          link: "settings/contacts",
        },
      ],
    },
    {
      title: "instructions",
      link: "instructions",
      icon: <CheckDocumentSvg />,
    },
  ];
};
